import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { ArrowUpDownIcon } from '../Icons/ArrowUpDownIcon/ArrowUpDownIcon'

type SortingStrategy = 'name' | 'age' | 'engagement'

interface CustomersSortSelectProps {
  sort: SortingStrategy
  setSort: (sort: SortingStrategy) => void
  ascending: boolean
  setAscending: React.Dispatch<React.SetStateAction<boolean>>
}

export function CustomersSortSelect({
  sort,
  setSort,
  ascending,
  setAscending,
}: CustomersSortSelectProps): ReactElement {
  const options: Array<{
    value: SortingStrategy
    label: string
    defaultAscending: boolean
  }> = [
    { value: 'name', label: 'A-Z', defaultAscending: true },
    { value: 'age', label: 'Idade', defaultAscending: true },
    { value: 'engagement', label: 'Engajamento', defaultAscending: false },
  ]

  return (
    <div className="flex shrink-0 flex-col flex-wrap justify-between gap-4 rounded-lg bg-dark-black p-3 xl:gap-3">
      <div className="flex items-center justify-between">
        <p className="text-sm font-semibold">Ordenar</p>
        <button
          onClick={() => {
            setAscending((prev) => !prev)
          }}
          className="border-box rounded border border-transparent bg-[#202020] p-1 transition-colors hover:bg-[#242424]"
        >
          <ArrowUpDownIcon
            active={ascending ? 'up' : 'down'}
            className="size-5"
          />
        </button>
      </div>
      <div className="flex gap-1 xl:flex-wrap">
        {options.map((option) => (
          <button
            key={option.value}
            onClick={() => {
              const previousSort = options.find((opt) => opt.value === sort)
              setSort(option.value)
              if (option.defaultAscending !== previousSort?.defaultAscending) {
                setAscending(option.defaultAscending)
              }
            }}
            className={twMerge(
              'box-border whitespace-nowrap rounded border-2 bg-[#202020] px-2.5 py-1 text-sm transition-colors',
              option.value === sort
                ? 'border-primary-main'
                : 'border-transparent',
            )}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  )
}
