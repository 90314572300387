import { useState, type ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { TeamMemberCard } from '@/components/Team/TeamMemberCard'
import { AddMemberModal } from '@/components/Team/AddMemberModal'
import { SelectNewRoleTypeModal } from '@/components/Team/SelectNewRoleTypeModal'
import { Loading } from '@/components/Global/Loading/Loading'

import { deviceStore } from '@/store/device'
import { useCurrentOrganization } from '@/store/organization'
import { teamStore } from '@/store/team'
import { useUserPermissions } from '@/store/user'

import { useGetTeamMembers } from '@/hooks/Team'

import { hasClearance } from '@/utils/rbac'

export function Team(): ReactElement {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false)
  const [showSelectNewRoleTypeModal, setShowSelectNewRoleTypeModal] =
    useState(false)

  const [isParentClosing, setIsParentClosing] = useState(false)

  const { isMobile } = deviceStore()
  const { currentOrganization } = useCurrentOrganization()
  const { setUser, resetPermissions, setSelectedEvents } = teamStore()
  const { organizationPermissions } = useUserPermissions()

  const navigate = useNavigate()

  const canManageTeam = hasClearance(organizationPermissions.access, 'EDITOR')

  const { teamMembers, isLoading } = useGetTeamMembers(
    currentOrganization?.organizer.organizationId ?? 0,
  )

  useEffect(() => {
    resetPermissions()
    setSelectedEvents([])
  }, [])

  if (isLoading) return <Loading />
  return (
    <div className="flex size-full flex-col gap-6 p-4">
      {canManageTeam && (
        <div className="flex w-full items-center justify-between">
          <button
            className="rounded-md bg-primary-main px-8 py-2 text-sm font-bold text-dark-black"
            onClick={() => {
              setShowAddMemberModal(true)
            }}
          >
            Adicionar membro
          </button>
        </div>
      )}

      <div className="columns-1 gap-2 desktop:columns-2">
        {teamMembers?.map((user) => {
          return (
            <TeamMemberCard
              key={user.username}
              isMobile={isMobile}
              userData={user}
              onClick={() => {
                navigate(`${user.username}`)
                setUser(user)
              }}
            />
          )
        })}
      </div>
      {showAddMemberModal && (
        <AddMemberModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowAddMemberModal, 400, false)
          }}
          handleNextStep={() => {
            setTimeout(setShowSelectNewRoleTypeModal, 400, true)
          }}
        />
      )}
      {showSelectNewRoleTypeModal && (
        <SelectNewRoleTypeModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowSelectNewRoleTypeModal, 400, false)
          }}
        />
      )}
    </div>
  )
}
