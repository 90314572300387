import { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { BsChevronRight } from 'react-icons/bs'

interface CustomerProfileEventCardProps {
  eventImageKey: string
  eventAlias: string
  eventName?: string
  eventDate?: string
  eventLocation?: string
}

export function CustomerProfileEventCard({
  eventImageKey,
  eventAlias,
  eventName,
  eventDate,
  eventLocation,
}: CustomerProfileEventCardProps): ReactElement {
  return (
    <Link
      to={`events/${eventAlias}/`}
      className="flex cursor-pointer items-center justify-between rounded-lg bg-dark-black p-2.5 transition-all hover:bg-dark-black/90"
    >
      <div className="flex gap-3">
        <img
          src={`${import.meta.env.VITE_S3 as string}/${eventImageKey}`}
          alt={`Foto de capa do evento "${eventName}"`}
          className={'size-[52px] min-w-[52px] rounded-sm'}
        />
        <div className="space-y-1 pt-0.5">
          <h3 className="text-sm/none font-semibold">{eventName}</h3>
          <p className="text-xs/none font-medium text-primary-main">
            {eventDate}
          </p>
          <p className="rounded text-xs/none text-[#e3e3e3]">{eventLocation}</p>
        </div>
      </div>
      <BsChevronRight strokeWidth={0.5} />
    </Link>
  )
}
