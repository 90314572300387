import { type IGrant } from '@/types/Grant'
import { create } from 'zustand'

interface GrantProps {
  currentUserGrant: IGrant | null
  allGrants: IGrant[] | null
  emailOrPhoneList: string[]
  setCurrentUser: (currentUser: IGrant) => void
  setAllGrants: (allGrants: IGrant[]) => void
  removeUserGrant: (userId: number) => void
  addEmailOrPhoneToList: (data: string) => void
  removeEmailOrPhoneToList: (data: string) => void
  resetEmailOrPhoneList: () => void
  reset: () => void
}

export const grantStore = create<GrantProps>((set, get) => {
  return {
    currentUserGrant: null,
    allGrants: null,
    emailOrPhoneList: [],
    setCurrentUser: (currentUserGrant: IGrant) => {
      set({ currentUserGrant })
    },
    setAllGrants: (allGrants: IGrant[]) => {
      set({ allGrants })
    },
    reset: () => {
      set({ allGrants: [] })
    },
    removeUserGrant: (grantId: number) => {
      const { allGrants } = get()
      const newGrantList = allGrants!.filter((grant) => grant.id !== grantId)
      set({ allGrants: newGrantList })
    },
    addEmailOrPhoneToList: (data: string) => {
      const { emailOrPhoneList } = get()
      const newList = [...emailOrPhoneList, data]
      set({ emailOrPhoneList: newList })
    },
    removeEmailOrPhoneToList: (data: string) => {
      const { emailOrPhoneList } = get()
      const newList = emailOrPhoneList.filter(
        (emailOrPhone) => emailOrPhone !== data,
      )
      set({ emailOrPhoneList: newList })
    },
    resetEmailOrPhoneList: () => {
      set({ emailOrPhoneList: [] })
    },
  }
})

export const useCurrentUserWithGrant = (): {
  currentUserGrant: IGrant | null
} => {
  return grantStore((state) => {
    const { currentUserGrant } = state

    return { currentUserGrant }
  })
}

export const useAllGrants = (): {
  allGrants: IGrant[] | null
} => {
  return grantStore((state) => {
    const { allGrants } = state

    return { allGrants }
  })
}

export const useAllEmailOrPhoneInList = (): {
  emailOrPhoneList: string[]
} => {
  return grantStore((state) => {
    const { emailOrPhoneList } = state

    return { emailOrPhoneList }
  })
}
