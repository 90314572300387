import { useState, type ReactElement, useEffect } from 'react'
import { motion } from 'framer-motion'

import { BarChart } from '@/components/Chart/BarChart'

import { useGetRevenuesByGenderByEventAlias } from '@/hooks/Analytics'

import {
  type TicketSalesByAgeDTO,
  type TicketSalesByGenderDTO,
} from '@/types/Analytics'

import { isTruthy } from '@/utils/validation'

import { SlArrowDown } from 'react-icons/sl'

interface AnalyticsGenderInfoCardProps {
  alias: string
}

export function AnalyticsAgeGenderCard({
  alias,
}: AnalyticsGenderInfoCardProps): ReactElement {
  const [firstLoad, setFirstLoad] = useState(true)
  const [currentParcialAge, setCurrentParcialAge] =
    useState<TicketSalesByAgeDTO | null>(null)
  const [currentParcialGender, setCurrentParcialGender] =
    useState<TicketSalesByGenderDTO | null>(null)
  const [showDetails, setShowDetails] = useState(false)

  const { getRevenuesByGenderByEventAlias: revenuesByGenderData } =
    useGetRevenuesByGenderByEventAlias(alias)

  useEffect(() => {
    if (isTruthy(revenuesByGenderData) && firstLoad) {
      setFirstLoad(false)
    }
  }, [revenuesByGenderData])

  if (firstLoad)
    return (
      <div className="h-[168px] w-full animate-pulse rounded-lg bg-dark-black" />
    )
  if (
    revenuesByGenderData?.age.length === 0 &&
    revenuesByGenderData.gender.length === 0
  )
    return <></>

  const revenuesByGender = revenuesByGenderData!.gender.filter(
    (item) => item.gender === 'MALE' || item.gender === 'FEMALE',
  )
  const totalGenderSplit = revenuesByGender.reduce(
    (acc, curr) => acc + curr.quantity,
    0,
  )
  const genderBarData = revenuesByGender.map((item) => {
    return {
      pv: item.quantity,
      label: item.gender === 'MALE' ? 'Masc' : 'Fem',
      percentage: (item.quantity / totalGenderSplit).toLocaleString('pt-BR', {
        style: 'percent',
      }),
    }
  })

  return (
    <div className="flex flex-col rounded-lg bg-dark-black p-4 pb-3">
      <div className="flex w-full justify-between ">
        <div className="flex w-[65%] flex-col gap-4">
          <header className="flex w-full flex-col justify-start gap-2">
            <span className="text-xl font-black leading-4">
              Público{' '}
              {currentParcialAge !== null
                ? `${currentParcialAge?.age} anos`
                : currentParcialGender !== null
                  ? `${
                      currentParcialGender.gender === 'MALE'
                        ? 'masculino'
                        : currentParcialGender.gender === 'FEMALE'
                          ? 'feminino'
                          : 'indefinido'
                    }`
                  : ''}
            </span>
            <span className="text-lg leading-4">
              {currentParcialAge !== null
                ? currentParcialAge.quantity
                : 'Idade'}
            </span>
          </header>
          <div className="relative w-full">
            <div className="flex h-20 w-full flex-col justify-start overflow-x-scroll px-0.5">
              {isTruthy(revenuesByGenderData) && (
                <BarChart
                  data={revenuesByGenderData!.age
                    .filter((age) => Number(age.age) < 100)
                    .map((item) => {
                      return {
                        pv: item.quantity,
                        label: item.age,
                      }
                    })}
                  onClickDo={(n: number) => {
                    const currentPartialRevenue = revenuesByGenderData?.age[n]
                    setCurrentParcialAge(currentPartialRevenue!)
                  }}
                  onClickUndo={() => {
                    setCurrentParcialAge(null)
                  }}
                  valueOnTop
                />
              )}
            </div>
            <div className="absolute -left-0.5 top-0 z-50 h-full w-2 bg-gradient-to-r from-dark-black to-transparent" />
            <div className="absolute -right-0.5 top-0 z-50 h-full w-2 bg-gradient-to-r from-transparent to-dark-black" />
          </div>
        </div>
        <div className="flex w-[30%] flex-col justify-between">
          <header className="flex  w-full justify-end">
            <span className="mt-6 text-lg leading-4">
              {currentParcialGender !== null
                ? currentParcialGender.quantity
                : 'Sexo'}
            </span>
          </header>
          <div className="flex h-24 w-full flex-col pt-2">
            {isTruthy(revenuesByGenderData) && (
              <BarChart
                data={genderBarData}
                onClickDo={(n: number) => {
                  const currentPartialRevenue = revenuesByGender[n]
                  setCurrentParcialGender(currentPartialRevenue)
                }}
                onClickUndo={() => {
                  setCurrentParcialGender(null)
                }}
                fullWidth
                topLabel="percentage"
              />
            )}
          </div>
        </div>
      </div>
      <motion.div
        className="mt-2 flex w-full flex-col overflow-hidden"
        key="details"
        initial={{ height: 0 }}
        animate={showDetails ? { height: 'auto' } : { height: 0 }}
        transition={{ duration: 0.25, ease: 'easeInOut' }}
      >
        {isTruthy(revenuesByGenderData?.gender) && (
          <div className="mt-2 flex flex-col">
            <span className="mb-2 text-lg font-bold">
              Proporção Masculino x Feminino
            </span>
            {revenuesByGenderData?.gender.map((item, index) => {
              if (item.gender === 'MALE')
                return <span key={index}>{item.quantity} homens</span>
              if (item.gender === 'FEMALE')
                return <span key={index}>{item.quantity} mulheres</span>
              return null
            })}
          </div>
        )}
        <div className="mt-5">
          <span className="text-lg font-bold leading-3">
            Distribuição de idades
          </span>
          <div className="relative">
            <div className="max-h-48 overflow-scroll py-2">
              {revenuesByGenderData?.age
                .filter((item) => Number(item.age) < 100)
                .map((item) => {
                  return (
                    <div key={item.age} className="flex gap-4">
                      <span className="line-clamp-1 w-16">{`${item.age} anos`}</span>
                      <span className="font-bold">{`${item.quantity} ${
                        item.quantity === 1 ? 'pessoa' : 'pessoas'
                      }`}</span>
                    </div>
                  )
                })}
            </div>
            <div className="absolute left-0 top-0 z-50 h-2 w-full bg-gradient-to-b  from-dark-black to-transparent" />
            <div className="absolute -bottom-0.5 left-0 z-50 h-2 w-full bg-gradient-to-t  from-dark-black to-transparent" />
          </div>
        </div>
      </motion.div>
      <motion.button
        className="mt-2 flex w-full items-center justify-center"
        onClick={() => {
          setShowDetails(!showDetails)
        }}
        initial={{ rotate: 0 }}
        animate={showDetails ? { rotate: 180 } : { rotate: 0 }}
        transition={{ duration: 0.25, ease: 'easeInOut' }}
      >
        <SlArrowDown size={10} className="w-5 stroke-[100px]" />
      </motion.button>
    </div>
  )
}
