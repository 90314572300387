import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface CustomersSummaryCardLoadingProps {
  className?: string
  isLoading: true
}

interface CustomersSummaryCardReadyProps {
  className?: string
  isLoading: false
  data: {
    customerCount: number
    averageAge: number
    predominantGender: 'male' | 'female' | null
  }
}

type CustomersSummaryCardProps =
  | CustomersSummaryCardLoadingProps
  | CustomersSummaryCardReadyProps

export function CustomersSummaryCard(
  props: CustomersSummaryCardProps,
): ReactElement {
  return (
    <div
      className={twMerge(
        'grid min-h-20 w-full grid-cols-3 gap-6 rounded-lg bg-dark-black px-4 py-3 lg:min-h-[84px] lg:gap-2',
        props.className,
      )}
    >
      <div>
        <h3 className="truncate pb-1.5 font-medium text-white">
          Qtde. de pessoas
        </h3>
        <div
          className={twMerge(
            'flex flex-col justify-center rounded-md',
            props.isLoading && 'h-8 w-28 animate-pulse bg-[#202020] lg:h-9',
          )}
        >
          <p className="text-2xl font-semibold text-primary-main lg:text-3xl">
            {!props.isLoading && props.data.customerCount}
          </p>
        </div>
      </div>
      <div>
        <h3 className="truncate pb-1.5 font-medium text-white">
          Média de idade
        </h3>
        <div
          className={twMerge(
            'flex flex-col justify-center rounded-md',
            props.isLoading && 'h-8 w-28 animate-pulse bg-[#202020] lg:h-9',
          )}
        >
          <p className="text-2xl font-semibold text-primary-main lg:text-3xl">
            {!props.isLoading &&
              `${Math.round(props.data.averageAge)} ${Math.round(props.data.averageAge) === 1 ? 'ano' : 'anos'}`}
          </p>
        </div>
      </div>
      <div>
        <h3 className="truncate pb-1.5 font-medium text-white">
          Gênero predominante
        </h3>
        <div
          className={twMerge(
            'flex flex-col justify-center rounded-md',
            props.isLoading && 'h-8 w-28 animate-pulse bg-[#202020] lg:h-9',
          )}
        >
          <p className="truncate text-2xl font-semibold text-primary-main lg:text-3xl">
            {props.isLoading
              ? null
              : props.data.predominantGender === 'male'
                ? 'Masculino'
                : props.data.predominantGender === 'female'
                  ? 'Feminino'
                  : 'Indefinido'}
          </p>
        </div>
      </div>
    </div>
  )
}
