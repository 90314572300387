import { useState, type ReactElement } from 'react'

import { Spinner } from '@/components/Global/Spinner'
import { SwitchButton } from '@/components/Global/SwitchButton'

import { HiOutlinePencil } from 'react-icons/hi2'
import { TbTrash } from 'react-icons/tb'

import { type ITicketSpec } from '@/types/Tickets'

import { useUserPermissions } from '@/store/user'
import { ticketStore } from '@/store/tickets'
import { errorStore } from '@/store/error'

import { hasClearance } from '@/utils/rbac'
import { usePutUpdateTicketSpec } from '@/hooks/Tickets/TicketSpec'
import { tv } from 'tailwind-variants'

interface TicketSpecCardHeaderProps {
  ticketSpec: ITicketSpec
  openTicketSpecModal: () => void
  deleteTicketSpec: () => void
  variant: 'small' | 'large'
}

export function TicketSpecCardHeader({
  ticketSpec,
  openTicketSpecModal,
  deleteTicketSpec,
  variant,
}: TicketSpecCardHeaderProps): ReactElement {
  const [switchState, setSwitchActive] = useState(ticketSpec.active)

  const iconSize = variant === 'small' ? 18 : 20

  // Variants
  const textVariant = tv({
    base: 'text-ellipsis text-nowrap overflow-hidden',
    variants: {
      size: {
        small: 'text-base font-bold',
        large: 'text-2xl font-bold',
      },
    },
  })

  // Store
  const { updateTicketSpec: updateTicketSpecStore } = ticketStore()
  const { eventPermissions } = useUserPermissions()
  const { showError } = errorStore()

  const canEditTicketSpec = hasClearance(eventPermissions.tickets, 'EDITOR')

  // Hooks
  const { updateTicketSpec, isLoading: isLoadingUpdateTicketSpec } =
    usePutUpdateTicketSpec()

  // Functions
  async function handleToggleTicketSpec(): Promise<void> {
    const response = await updateTicketSpec({
      ticketSpecId: ticketSpec.id,
      active: !switchState,
    })
    if (!response.status) {
      showError('Erro ao atualizar o ingresso. Tente novamente!')
      return
    }
    if (response.ticketSpec !== undefined)
      updateTicketSpecStore(response.ticketSpec)
  }

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex w-[calc(100%-60px)] items-center gap-4">
        <span className={textVariant({ size: variant })}>
          {ticketSpec.description}
        </span>
        <div className="flex h-[20px] flex-row items-center gap-2">
          <SwitchButton
            active={switchState}
            handleOnClick={async () => {
              setSwitchActive((prev) => !(prev ?? false))
              await handleToggleTicketSpec()
            }}
            disable={isLoadingUpdateTicketSpec || !canEditTicketSpec}
          />

          {isLoadingUpdateTicketSpec && (
            <Spinner
              borderWidth="border-[3px]"
              borderColor="border-primary-main/50"
              bottomBorderColor="border-b-primary-main"
              width="w-4"
              height="h-4"
            />
          )}
        </div>
      </div>

      <div className="flex items-center gap-2">
        {ticketSpec.numberOfSoldTickets === 0 && (
          <TbTrash
            size={iconSize}
            onClick={deleteTicketSpec}
            className="hover:cursor-pointer"
          />
        )}
        <HiOutlinePencil
          size={iconSize - 2}
          onClick={() => {
            openTicketSpecModal()
          }}
          className="hover:cursor-pointer"
        />
      </div>
    </div>
  )
}
