import {
  useState,
  type ReactElement,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import { useParams } from 'react-router-dom'

import { useGetInfiniteListUsers } from '@/hooks/List'

import { type IUserData } from '@/types/List'

import { ListUserCard } from '@/components/List/ListUserCard'
import { ListUserInfoModal } from '@/components/List/ListUserInfoModal'
import { Spinner } from '@/components/Global/Spinner'
import { DebounceSearchBar } from '@/components/Global/DebounceSearchBar'

const TAKE = 50

export function List(): ReactElement {
  const [userTicketInfoModalIsOpen, setUserTicketInfoModalIsOpen] =
    useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [currentUser, setCurrentUser] = useState<IUserData>()

  const mainDivRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const { alias: eventAlias } = useParams()
  const [searchQuery, setSearchQuery] = useState('')
  const { data, size, setSize, isValidating, isLoading, mutate } =
    useGetInfiniteListUsers(
      eventAlias!,
      {
        term: searchQuery,
      },
      TAKE,
    )

  const observerRef = useRef<HTMLDivElement | null>(null)
  const items = data !== undefined ? data.flatMap((page) => page) : []
  const hasMore = data === undefined || data[data.length - 1]?.length === TAKE

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries
      if (entry.isIntersecting && hasMore && !isLoading && !isValidating) {
        void setSize(size + 1)
      }
    },
    [size, hasMore, setSize, isLoading, isValidating],
  )

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 1.0,
    })
    if (observerRef.current !== null) observer.observe(observerRef.current)
    return () => {
      observer.disconnect()
    }
  }, [handleObserver])

  function handleOnClick(user: IUserData): void {
    setCurrentUser(user)
    setUserTicketInfoModalIsOpen(true)
  }

  async function updateState(): Promise<void> {
    await mutate()
  }

  return (
    <div
      ref={mainDivRef}
      className="flex size-full flex-col items-center gap-4"
    >
      <div className="flex w-full max-w-[600px] flex-col gap-4 px-4 pt-4">
        <DebounceSearchBar
          ref={searchInputRef}
          placeholder="Username, email ou celular"
          value={searchQuery}
          onChangeText={(newText) => {
            setSearchQuery(newText)
          }}
          onCancel={() => {
            setSearchQuery('')
          }}
        />
        <div className="flex w-full flex-col flex-wrap gap-4 pb-6">
          {isLoading ? (
            <div className="flex items-center justify-center p-40">
              <Spinner
                borderWidth="border-4"
                borderColor="border-primary-main/50"
                bottomBorderColor="border-b-primary-main"
              />
            </div>
          ) : (
            <div>
              {items.map((user) => (
                <ListUserCard
                  user={user}
                  handleOnClick={(user: IUserData) => {
                    handleOnClick(user)
                  }}
                  key={user.id}
                />
              ))}
            </div>
          )}
          <div ref={observerRef} style={{ height: 1 }} />
          {!isLoading && isValidating && (
            <div className="mb-5 flex w-full items-center justify-center gap-4 pb-6">
              <Spinner
                borderWidth="border-4"
                borderColor="border-primary-main/50"
                bottomBorderColor="border-b-primary-main"
              />
            </div>
          )}
        </div>
      </div>
      {userTicketInfoModalIsOpen && (
        <ListUserInfoModal
          closeModal={() => {
            setIsParentClosing(false)
            setTimeout(setUserTicketInfoModalIsOpen, 400, false)
          }}
          isParentClosing={isParentClosing}
          handleModalClose={() => {
            setIsParentClosing(true)
            setTimeout(setUserTicketInfoModalIsOpen, 400, true)
          }}
          currentUser={currentUser}
          updateState={updateState}
        />
      )}
    </div>
  )
}
