import { useState } from 'react'
import { type AxiosResponse } from 'axios'

import { api } from '@/services/axios'

import { type IBatch } from '@/types/Tickets'

interface UpdateBatch {
  batchId: number
  description?: string
  numberOfAvailableTickets?: number
  userLimit?: number
  followerId?: number | null
}

interface PutReturnProps {
  status: boolean
  batch?: IBatch
}

export const usePutUpdateBatch = (): {
  updateBatch: (body: UpdateBatch) => Promise<PutReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function updateBatch({
    batchId,
    ...body
  }: UpdateBatch): Promise<PutReturnProps> {
    setIsLoading(true)
    try {
      const response: AxiosResponse<IBatch> = await api.put(
        `/admin/batches/${batchId}`,
        body,
      )

      setIsLoading(false)

      return {
        status: true,
        batch: response.data,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
      }
    }
  }

  return {
    updateBatch,
    isLoading,
  }
}
