import { useState, type ReactElement, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { DeleteIcon } from '@/components/Icons/DeleteIcon'

interface ModalDesktopProps {
  className?: string
  closeModal?: () => void
  children: ReactElement
  disableTouchToClose?: boolean
  isParentClosing?: boolean
  isError?: boolean
  errorMessage?: string
  hasRoundedBorder?: boolean
  // isParentClosing is responsible for closing the Modal internally before the parent component unmounts it
}

export function ModalDesktop({
  className,
  closeModal,
  children,
  disableTouchToClose = false,
  isParentClosing = false,
}: ModalDesktopProps): ReactElement {
  const [renderOverlay, setRenderOverlay] = useState(true)
  const [renderModal, setRenderModal] = useState(false)

  const modalRef = useRef<HTMLDivElement>(null)
  const modalDivRef = useRef<HTMLDivElement>(null)

  function handleModalClose(): void {
    document.body.style.overflow = ''
    setRenderModal(false)
    setTimeout(setRenderOverlay, 200, false)
    if (closeModal !== undefined) setTimeout(closeModal, 200)
  }

  // Render overlay first, then render modal
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    setTimeout(setRenderModal, 200, true)
  }, [])

  useEffect(() => {
    document.body.style.overflow = ''
    if (isParentClosing) handleModalClose()
  }, [isParentClosing])

  return (
    <AnimatePresence>
      {renderOverlay && (
        <motion.div
          key="overlay"
          className="fixed inset-0 z-30 h-max min-h-full w-full backdrop-blur-sm backdrop-brightness-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          onClick={() => {
            if (!disableTouchToClose) handleModalClose()
          }}
        />
      )}

      {renderModal && (
        <motion.div
          key="modal"
          className={twMerge(
            'fixed left-1/2 top-1/2 z-50 flex w-full max-w-[600px] -translate-x-1/2 -translate-y-1/2 flex-col items-end',
            className,
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          ref={modalRef}
        >
          {!disableTouchToClose && (
            <button
              className="absolute -top-10 flex items-center justify-center gap-2 rounded-lg bg-white px-2 py-1"
              onClick={handleModalClose}
            >
              <span className="text-sm font-bold text-dark-black">FECHAR</span>
              <DeleteIcon onClick={handleModalClose} variant="dark" />
            </button>
          )}
          <>
            <div
              className={twMerge(
                'relative z-30 flex max-h-screen w-full flex-col items-center justify-center overflow-scroll rounded-xl bg-white p-4',
              )}
              ref={modalDivRef}
            >
              {children}
            </div>
          </>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
