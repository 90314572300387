import { type ReactNode, useEffect, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { deviceStore } from '@/store/device'

interface CustomerAnalyticsWrapperProps {
  children?: ReactNode
}

export function CustomerAnalyticsWrapper({
  children,
}: CustomerAnalyticsWrapperProps): ReactElement {
  const navigate = useNavigate()
  const { isMobile } = deviceStore()

  useEffect(() => {
    if (isMobile) {
      navigate('/?action=customer-analytics-mobile', { replace: true })
    }
  }, [isMobile])

  return <>{children}</>
}
