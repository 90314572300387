import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { useParams } from 'react-router-dom'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'

import { errorStore, useCurrentError } from '@/store/error'
import { deviceStore } from '@/store/device'
import { useUserPermissions } from '@/store/user'

import { type IWithdrawal } from '@/types/Finance'

import { useDeleteWithdrawalById } from '@/hooks/Finance'

import { numberToReais } from '@/utils/formatNumber'
import { fullDateFormat } from '@/utils/formatData'
import { isTruthy } from '@/utils/validation'
import { hasClearance } from '@/utils/rbac'

import { IoMdShare } from 'react-icons/io'

import { DELETE_WITHDRAWAL } from '@/errors'

interface WithdrawalModalProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  withdrawal: IWithdrawal
}

export function WithdrawalModal({
  closeModal,
  handleModalClose,
  isParentClosing,
  withdrawal,
}: WithdrawalModalProps): ReactElement {
  const { isError, errorMessage } = useCurrentError()
  const { isMobile } = deviceStore()
  const { eventPermissions } = useUserPermissions()

  const { alias } = useParams()

  const { deleteWithdrawal, isLoading } = useDeleteWithdrawalById(alias!)

  const { showError } = errorStore()

  const withdrawalStatus =
    withdrawal.status === 'PENDING'
      ? 'Em análise'
      : withdrawal.status === 'APPROVED'
        ? 'Aprovado'
        : withdrawal.status === 'REJECTED'
          ? 'Negado'
          : 'Cancelado'

  const colorStatus =
    withdrawal.status === 'PENDING'
      ? 'bg-tonal-orange'
      : withdrawal.status === 'APPROVED'
        ? 'bg-primary-main'
        : withdrawal.status === 'REJECTED'
          ? 'bg-tonal-red'
          : 'bg-tonal-red'

  const canEditFinance = hasClearance(eventPermissions.finance, 'EDITOR')

  async function handleDeleteWithdrawal(): Promise<void> {
    const response = await deleteWithdrawal(withdrawal.id)
    if (response.status === 200) {
      handleModalClose()
      return
    }
    showError(DELETE_WITHDRAWAL)
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        <div className="flex w-full flex-col gap-4">
          <div className="flex flex-col gap-1">
            <span className="text-2xl font-black leading-6">
              {withdrawal.title}
            </span>
            <span className="text-sm leading-4">{withdrawal.description}</span>
          </div>
          <span className="text-2xl font-bold">
            {numberToReais(withdrawal.amount, 2)}
          </span>
          <div className="flex flex-col gap-4 rounded-lg bg-dark-light-gray p-4">
            <div className="flex gap-2">
              <img
                src={`${import.meta.env.VITE_S3 as string}/${
                  withdrawal.user.profileImageKey
                }`}
                className="aspect-square size-14 rounded-full object-cover"
                alt="Solicitante do saque"
              />
              <div className="flex flex-col justify-center">
                <span className="text-sm leading-3 text-text-secondary">
                  Solicitado por
                </span>
                <span className="text-lg font-bold leading-6">
                  {withdrawal.user.firstName} {withdrawal.user.lastName}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-0.5">
              <span className="mb-0.5 text-sm leading-3 text-text-secondary">
                Destino
              </span>
              <span className="text-md font-bold leading-4">
                {withdrawal.pixName}
              </span>
              <span className="text-md leading-4">{withdrawal.pixKey}</span>
            </div>
          </div>
          <div className="flex flex-col gap-3 rounded-lg bg-dark-light-gray p-4">
            <div className="flex flex-col">
              <span className="text-sm leading-3 text-text-secondary">
                Status
              </span>
              <div className="flex items-center gap-1">
                <div className={twMerge('h-2 w-2 rounded-full', colorStatus)} />
                <span className="font-bold leading-5">{withdrawalStatus}</span>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-sm leading-3 text-text-secondary">
                Data da solicitação
              </span>
              <span className="text-xs leading-5">
                {fullDateFormat(withdrawal.createdAt)}
              </span>
            </div>
            {isTruthy(withdrawal.invoiceKey) && (
              <div className="flex flex-col">
                <span className="text-sm leading-3 text-text-secondary">
                  Nota fiscal
                </span>
                <div
                  className="flex w-full items-center justify-start gap-4 hover:cursor-pointer"
                  onClick={() => {
                    window.open(
                      `${import.meta.env.VITE_S3 as string}/${
                        withdrawal.invoiceKey
                      }`,
                    )
                  }}
                >
                  <button className="mt-1.5 rounded-lg border border-dark-black px-3 py-1 text-sm">
                    Visualizar arquivo
                  </button>
                </div>
              </div>
            )}
          </div>
          {withdrawal.status === 'PENDING' && canEditFinance && (
            <div className="mt-4 h-12 w-full max-w-[300px] self-center">
              <Button
                enabled={!isLoading}
                text="Cancelar saque"
                onClick={() => {
                  void handleDeleteWithdrawal()
                }}
                className="bg-dark-light-gray"
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
        <div
          className="absolute right-5 top-5 hover:cursor-pointer"
          onClick={() => {
            window.open(
              `${import.meta.env.VITE_S3 as string}/${withdrawal.receiptKey}`,
            )
          }}
        >
          <IoMdShare size={24} />
        </div>
      </div>
    </Modal>
  )
}
