import { type IPermissionEnum } from '@/types/Team'

export function hasClearance(
  userClearenceInput: IPermissionEnum | boolean | null | undefined,
  requiredClearenceInput: IPermissionEnum | boolean,
): boolean {
  if (typeof requiredClearenceInput === 'boolean') {
    return userClearenceInput === requiredClearenceInput
  }

  if (userClearenceInput === null || userClearenceInput === undefined)
    return false

  const rolePermissions = ['FORBIDDEN', 'VIEWER', 'EDITOR']

  const userClearence = rolePermissions.indexOf(
    userClearenceInput as IPermissionEnum,
  )
  const requiredClearence = rolePermissions.indexOf(requiredClearenceInput)

  if (userClearence === -1 || requiredClearence === -1) return false

  return userClearence >= requiredClearence
}
