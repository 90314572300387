import { type ElementType, type ReactElement, useState } from 'react'
import { Modal } from '@/components/Global/Modal'
import { deviceStore } from '@/store/device'
import { LuCheck, LuX } from 'react-icons/lu'
import { Button } from '@/components/Global/Button'
import { isTruthy } from '@/utils/validation'
import { api } from '@/services/axios'
import { useCurrentOrganization } from '@/store/organization'
import { twMerge } from 'tailwind-merge'
import { Spinner } from '../Global/Spinner'

type Filter = 'query' | 'age' | 'gender'

interface CustomersExportDataModalProps {
  setShowModal: (value: boolean) => void
  isParentClosing: boolean
  setIsParentClosing: (value: boolean) => void
  appliedFilters: Array<{
    label: string
    value: Filter
  }>
  events?: Array<{
    title: string
    imageKey: string
    id: number
    ticketSpecs: Array<{
      id: number
      batchDescription?: string
      ticketSpecDescription: string
    }>
  }>
  ticketSpecIds: number[]
  queryParams: {
    q: string
    sortBy: 'age' | 'name' | 'engagement'
    order: string
    gender: Array<'male' | 'female' | 'unknown'>
    ageEq: number | undefined
    ageGte: number | undefined
    ageLte: number | undefined
    ticketSpecIds: number[]
  }
}

export function CustomersExportDataModal({
  setShowModal,
  isParentClosing,
  setIsParentClosing,
  appliedFilters: appliedFilterProps,
  events,
  ticketSpecIds,
  queryParams,
}: CustomersExportDataModalProps): ReactElement {
  const { isMobile } = deviceStore()
  const [appliedFilters, setAppliedFilters] = useState<
    Array<{
      label: string
      value: Filter
    }>
  >(appliedFilterProps)

  const [exportWithoutFiltersIsLoading, setExportWithoutFiltersIsLoading] =
    useState(false)
  const [exportWithFiltersIsLoading, setExportWithFiltersIsLoading] =
    useState(false)
  const [exportWithoutFiltersSuccessful, setExportWithoutFiltersSuccessful] =
    useState(false)
  const [exportWithFiltersSuccessful, setExportWithFiltersSuccessful] =
    useState(false)

  const eventsWithFilteredTicketSpecs =
    events !== undefined
      ? events
          .map(({ ticketSpecs, ...event }) => {
            const selectedTicketSpecs = ticketSpecs.filter((ticketSpec) =>
              ticketSpecIds.includes(ticketSpec.id),
            )

            return {
              event,
              selectedTicketSpecs,
              totalNumberOfEventTicketSpecs: ticketSpecs.length,
            }
          })
          .filter(({ selectedTicketSpecs }) => selectedTicketSpecs.length > 0)
      : []

  const { currentOrganization } = useCurrentOrganization()
  const organizationId: number = currentOrganization?.organizer
    .organizationId as number

  const handleExportDataWithFilters = async (): Promise<void> => {
    const reportQueryParams = {
      ...(appliedFilters.some((filter) => filter.value === 'query') && {
        q: queryParams.q,
      }),
      ...(appliedFilters.some((filter) => filter.value === 'gender') && {
        gender: queryParams.gender,
      }),
      ...(appliedFilters.some((filter) => filter.value === 'age') && {
        ageEq: queryParams.ageEq,
      }),
      ...(appliedFilters.some((filter) => filter.value === 'age') && {
        ageGte: queryParams.ageGte,
      }),
      ...(appliedFilters.some((filter) => filter.value === 'age') && {
        ageLte: queryParams.ageLte,
      }),
      ticketSpecIds: queryParams.ticketSpecIds,
    }

    setExportWithFiltersIsLoading(true)
    try {
      await api.get(
        `/admin/analytics/organizations/${organizationId}/customers-report`,
        {
          params: reportQueryParams,
        },
      )
      setExportWithFiltersSuccessful(true)
    } catch (error) {
    } finally {
      setExportWithFiltersIsLoading(false)
    }
  }

  const handleExportDataWithoutFilters = async (): Promise<void> => {
    setExportWithoutFiltersIsLoading(true)
    try {
      await api.get(
        `/admin/analytics/organizations/${organizationId}/customers-report`,
      )
      setExportWithoutFiltersSuccessful(true)
    } catch (error) {
    } finally {
      setExportWithoutFiltersIsLoading(false)
    }
  }

  return (
    <Modal
      className="w-full max-w-md"
      closeModal={() => {
        setIsParentClosing(true)
        setTimeout(setIsParentClosing, 400, false)
        setTimeout(setShowModal, 400, false)
      }}
      isParentClosing={isParentClosing}
      isMobile={isMobile}
    >
      <div className="h-fit w-full p-1.5 text-dark-black">
        <h2 className="mb-1 text-2xl font-bold">Exportar dados</h2>
        {(appliedFilters.length > 0 ||
          eventsWithFilteredTicketSpecs.length > 0) && (
          <p className="mb-4">
            O relatório será enviado para o seu e-mail cadastrado.
          </p>
        )}
        {appliedFilters.length > 0 ||
        eventsWithFilteredTicketSpecs.length > 0 ? (
          <>
            {appliedFilters.length > 0 && (
              <>
                <h3 className="mb-2.5 font-bold">Filtros aplicados</h3>
                <div className="mb-6 space-x-2">
                  {appliedFilters.map((filter) => (
                    <div
                      key={filter.value}
                      className="inline-flex items-center justify-between gap-2.5 rounded-full bg-[#f4f4f4] px-2.5 py-1.5 pr-2 text-sm"
                    >
                      <span className="leading-none">{filter.label}</span>
                      <button
                        onClick={() => {
                          setAppliedFilters(
                            appliedFilters.filter(
                              (appliedFilter) =>
                                appliedFilter.value !== filter.value,
                            ),
                          )
                        }}
                        className="flex items-center justify-center rounded-full bg-[#202020] p-[3px] transition-all hover:bg-[#202020]/90"
                      >
                        <LuX size={12} className="text-white" />
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
            {eventsWithFilteredTicketSpecs.length > 0 && (
              <div className="mb-4">
                <h3 className="mb-2 font-bold">Eventos selecionados</h3>
                <ul className="mb-6 space-y-4 pl-px">
                  {eventsWithFilteredTicketSpecs.map(
                    ({
                      event,
                      selectedTicketSpecs,
                      totalNumberOfEventTicketSpecs,
                    }) => (
                      <li
                        key={event.id}
                        className="flex items-center justify-between gap-2 text-sm"
                      >
                        <div className="flex items-center gap-2">
                          <img
                            className="size-8 rounded-sm"
                            src={`${import.meta.env.VITE_S3 as string}/${event.imageKey}`}
                            alt={`Foto de capa do evento "${event.title}".`}
                          />
                          <p className="truncate">{event.title}</p>
                        </div>
                        <p className="text-xs text-dark-dark-gray">
                          {selectedTicketSpecs.length ===
                          totalNumberOfEventTicketSpecs
                            ? 'Todos os lotes selecionados'
                            : selectedTicketSpecs.length > 1
                              ? 'Lotes variados selecionados'
                              : isTruthy(
                                    selectedTicketSpecs[0].batchDescription,
                                  )
                                ? `${selectedTicketSpecs[0].batchDescription} - ${selectedTicketSpecs[0].ticketSpecDescription}`
                                : selectedTicketSpecs[0].batchDescription}
                        </p>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            )}
            <div className="space-y-2">
              <button
                onClick={
                  exportWithFiltersSuccessful
                    ? undefined
                    : handleExportDataWithFilters
                }
                disabled={
                  exportWithFiltersIsLoading ||
                  exportWithoutFiltersIsLoading ||
                  exportWithoutFiltersSuccessful
                }
                className={twMerge(
                  'relative flex h-full min-h-[52px] w-full items-center justify-center rounded-full bg-primary-main py-3 text-lg font-bold text-black transition-all disabled:cursor-not-allowed disabled:bg-[#A2A2A2] disabled:text-[#656565]',
                  exportWithoutFiltersSuccessful || exportWithFiltersSuccessful
                    ? 'cursor-default'
                    : 'enabled:hover:bg-primary-main/90',
                )}
              >
                {exportWithFiltersIsLoading ? (
                  <Spinner
                    borderWidth="border-4"
                    borderColor="border-background-main/50"
                    bottomBorderColor="border-b-background-main"
                    width="w-5"
                    height="h-5"
                  />
                ) : exportWithFiltersSuccessful ? (
                  <LuCheck size={24} />
                ) : (
                  'Exportar com filtros'
                )}
              </button>
              <button
                onClick={
                  exportWithoutFiltersSuccessful
                    ? undefined
                    : handleExportDataWithoutFilters
                }
                disabled={
                  exportWithFiltersIsLoading ||
                  exportWithoutFiltersIsLoading ||
                  exportWithFiltersSuccessful
                }
                className={twMerge(
                  'relative flex h-full min-h-[52px] w-full items-center justify-center rounded-full bg-dark-light-gray py-3 text-lg font-bold text-black transition-all disabled:cursor-not-allowed disabled:bg-[#A2A2A2] disabled:text-[#656565]',
                  exportWithoutFiltersSuccessful || exportWithFiltersSuccessful
                    ? 'cursor-default'
                    : 'enabled:hover:bg-dark-light-gray/80',
                )}
              >
                {exportWithoutFiltersIsLoading ? (
                  <Spinner
                    borderWidth="border-4"
                    borderColor="border-background-main/50"
                    bottomBorderColor="border-b-background-main"
                    width="w-5"
                    height="h-5"
                  />
                ) : exportWithoutFiltersSuccessful ? (
                  <LuCheck size={24} />
                ) : (
                  'Exportar sem filtros'
                )}
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="pb-4">
              Ao clicar em exportar dados, você receberá o relatório de clientes
              por e-mail.
            </p>
            <div className="space-y-2">
              <Button
                onClick={
                  exportWithoutFiltersSuccessful
                    ? undefined
                    : handleExportDataWithoutFilters
                }
                isLoading={exportWithoutFiltersIsLoading}
                enabled={!exportWithoutFiltersIsLoading}
                className={twMerge(
                  'min-h-[52px] w-full py-3 transition-all',
                  exportWithoutFiltersSuccessful
                    ? 'cursor-default'
                    : 'enabled:hover:bg-primary-main/90',
                )}
                text={
                  exportWithoutFiltersSuccessful ? undefined : 'Exportar dados'
                }
                icon={
                  exportWithoutFiltersSuccessful
                    ? (LuCheck as ElementType)
                    : undefined
                }
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
