import { useState, type ReactElement, useEffect } from 'react'
import { isToday, isYesterday } from 'date-fns'

import { CommunicationCampaignCard } from '@/components/CommunicationCampaigns/CommunicationCampaignCard'
import { CreateCommunicationCampaignSelectModal } from '@/components/CommunicationCampaigns/CreateCommunicationCampaignSelectModal'
import { Button } from '@/components/Global/Button'
import { CommunicationCampaignSummaryModal } from '@/components/CommunicationCampaigns/CommunicationCampaignSummaryModal'

import { type ICommunicationCampaign } from '@/types/CommunicationCampaign'

import { useCommunicationCampaignStore } from '@/store/communicationCampaign'
import { useCurrentOrganization } from '@/store/organization'
import { useUserPermissions } from '@/store/user'

import {
  useGetCommunicationCampaigns,
  useDeleteCommunicationCampaignById,
} from '@/hooks/CummunicationCampaigns'

import { isTruthy } from '@/utils/validation'
import { dateToFormat } from '@/utils/formatData'
import { hasClearance } from '@/utils/rbac'

export function CommunicationCampaigns(): ReactElement {
  const [
    showCreateCommunicationCampaignSelectModal,
    setShowCreateCommunicationCampaignSelectModal,
  ] = useState(false)
  const [
    showCummunicationCampaignSummaryModal,
    setShowCummunicationCampaignSummaryModal,
  ] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [currentCampaign, setCurrentCampaign] =
    useState<ICommunicationCampaign>()
  const [groupedCommunicationCampaigns, setGroupedCommunicationCampaigns] =
    useState<Record<string, ICommunicationCampaign[]>>({})

  const { updateProperty } = useCommunicationCampaignStore()
  const { currentOrganization } = useCurrentOrganization()
  const { organizationPermissions } = useUserPermissions()

  const { communicationCampaigns, refresh } = useGetCommunicationCampaigns(
    currentOrganization?.organizer.organizationId ?? 0,
  )
  const { deleteCommunicationCampaign, isLoading } =
    useDeleteCommunicationCampaignById(
      currentOrganization?.organizer.organizationId ?? 0,
    )

  function splitCommunicationCampaignsByDate(
    rawCampaignData: ICommunicationCampaign[],
  ): Record<string, ICommunicationCampaign[]> {
    const tempGroupedCampaigns: Record<string, ICommunicationCampaign[]> = {}

    const sortedCampaigns = rawCampaignData.sort((a, b) => {
      return new Date(a.createdAt!) < new Date(b.createdAt!) ? 1 : -1
    })

    for (const campaign of sortedCampaigns) {
      const date = campaign.createdAt!.split('T')[0]

      if (!isTruthy(tempGroupedCampaigns[date])) {
        tempGroupedCampaigns[date] = []
      }
      tempGroupedCampaigns[date].push(campaign)
    }

    return tempGroupedCampaigns
  }

  useEffect(() => {
    if (communicationCampaigns !== undefined) {
      const campaignNames = communicationCampaigns?.map(
        (campaign) => campaign.name,
      )
      updateProperty('campaignNamesInUse', campaignNames)

      const tempSplittedCampaigns = splitCommunicationCampaignsByDate(
        communicationCampaigns,
      )
      setGroupedCommunicationCampaigns(tempSplittedCampaigns)
    }
  }, [communicationCampaigns])

  return (
    <div className="mt-4 flex size-full flex-col">
      <div className="mb-16 flex flex-col gap-4 p-4 pt-0">
        {Object.keys(groupedCommunicationCampaigns ?? {}).map((date) => {
          const campaignDate = new Date(`${date}T12:00:00.000Z`)

          const formattedDate = isToday(campaignDate)
            ? 'Hoje'
            : isYesterday(campaignDate)
              ? 'Ontem'
              : dateToFormat(date)

          return (
            <div key={date} className="flex w-full  flex-col gap-2 ">
              <span className="font-bold">{formattedDate}</span>
              <div className="w-full columns-1 desktop:columns-2">
                {groupedCommunicationCampaigns[date].map((campaign, index) => {
                  return (
                    <div
                      className="w-full break-inside-avoid-column"
                      key={index}
                    >
                      <CommunicationCampaignCard
                        key={index}
                        name={campaign.name}
                        scheduledFor={campaign.scheduledFor}
                        status={campaign.status!}
                        usersImpacted={
                          campaign.subscribers ?? campaign.estimatedSubscribers
                        }
                        onClick={() => {
                          setCurrentCampaign(campaign)
                          setShowCummunicationCampaignSummaryModal(true)
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
      {hasClearance(
        organizationPermissions.communicationCampaign,
        'EDITOR',
      ) && (
        <div className="fixed bottom-4 left-1/2 z-10 flex h-12 w-fit min-w-[300px] -translate-x-1/2 items-center justify-center px-4 desktop:absolute">
          <Button
            text="Criar campanha"
            enabled
            onClick={() => {
              setShowCreateCommunicationCampaignSelectModal(true)
            }}
          />
        </div>
      )}
      {showCreateCommunicationCampaignSelectModal && (
        <CreateCommunicationCampaignSelectModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(
              setShowCreateCommunicationCampaignSelectModal,
              400,
              false,
            )
          }}
        />
      )}
      {showCummunicationCampaignSummaryModal && (
        <CommunicationCampaignSummaryModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowCummunicationCampaignSummaryModal, 400, false)
          }}
          variant="VIEW"
          campaignData={currentCampaign!}
          cancelCampaign={deleteCommunicationCampaign}
          isLoading={isLoading}
          refresh={refresh}
        />
      )}
    </div>
  )
}
