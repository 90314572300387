import { useEffect, useState, type ReactElement } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'

import { useCurrentOrganization } from '@/store/organization'
import { updateEventStore } from '@/store/updateEvent'
import { useUserPermissions } from '@/store/user'
import { deviceStore } from '@/store/device'

import { hasClearance } from '@/utils/rbac'

import { LuCalendarPlus } from 'react-icons/lu'
import { TbSpeakerphone } from 'react-icons/tb'
import { FaPeopleGroup } from 'react-icons/fa6'
import { FaRegEdit } from 'react-icons/fa'
import { IoStarOutline } from 'react-icons/io5'
import { FiSend } from 'react-icons/fi'

interface HomeIconMenuProps {
  setShowInviteProducerModal: (state: boolean) => void
}

export function HomeIconMenu({
  setShowInviteProducerModal,
}: HomeIconMenuProps): ReactElement {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const { isMobile } = deviceStore()
  const { updateAnyState } = updateEventStore()
  const { organizationPermissions } = useUserPermissions()
  const { currentOrganization } = useCurrentOrganization()

  function handleModalClose(): void {
    setIsParentClosing(true)
    setTimeout(setShowConfirmationModal, 400, false)
    setTimeout(setIsParentClosing, 400, false)
  }

  const canCreateEvent = hasClearance(
    organizationPermissions.eventCreation,
    true,
  )
  const canViewCommunicationCampaigns = hasClearance(
    organizationPermissions.communicationCampaign,
    'VIEWER',
  )
  const canViewTeam = hasClearance(organizationPermissions.access, 'VIEWER')
  const canEditOrganization = hasClearance(
    organizationPermissions.settings,
    true,
  )
  const canInviteProducer = hasClearance(organizationPermissions.settings, true)

  useEffect(() => {
    const actionParam = searchParams.get('action')
    if (actionParam === 'customer-analytics-mobile') {
      setShowConfirmationModal(true)
      setSearchParams({}, { replace: true })
    }
  }, [searchParams, setSearchParams])

  return (
    <div className="relative mt-4 w-full">
      <div className="flex size-full justify-start gap-4 overflow-y-hidden overflow-x-scroll scroll-smooth px-4">
        <div className="flex gap-2">
          {canCreateEvent && (
            <div
              className="flex w-24 cursor-pointer flex-col items-center gap-3"
              onClick={() => {
                navigate('/create-event', {
                  state: { resetStore: 1 },
                })

                updateAnyState({ typeOperation: 'Create' })
              }}
            >
              <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main">
                <LuCalendarPlus size={32} />
              </div>
              <span className="h-8 text-center text-sm font-medium leading-4">
                Criar um evento
              </span>
            </div>
          )}
          {canEditOrganization && (
            <Link
              to={`/organizations/${currentOrganization?.organizer.organizationId}`}
              className="flex w-24 flex-col items-center gap-3"
            >
              <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main hover:cursor-pointer">
                <FaRegEdit size={28} />
              </div>
              <span className="text-center text-sm font-medium leading-4">
                Editar organização
              </span>
            </Link>
          )}
          {canInviteProducer && (
            <div className="flex w-24 flex-col items-center gap-3">
              <button
                onClick={() => {
                  setShowConfirmationModal(true)
                }}
                className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main hover:cursor-pointer"
              >
                <IoStarOutline size={36} />
              </button>
              <span className="text-center text-sm font-medium leading-4">
                Clientes
              </span>
            </div>
          )}
          {canInviteProducer && (
            <div
              className="flex w-24 flex-col items-center gap-3"
              onClick={() => {
                setShowInviteProducerModal(true)
              }}
            >
              <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main hover:cursor-pointer">
                <FiSend size={28} />
              </div>
              <span className="text-center text-sm font-medium leading-4">
                Indicar um produtor
              </span>
            </div>
          )}
          {canViewCommunicationCampaigns && (
            <Link
              to={`/communication-campaigns`}
              className="flex w-24 flex-col items-center gap-3"
            >
              <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main">
                <TbSpeakerphone size={36} />
              </div>
              <span className="line-clamp-2 text-center text-sm font-medium leading-4">
                Envio de comunicações
              </span>
            </Link>
          )}
          {canViewTeam && (
            <Link
              to={`/team`}
              className="flex w-24 flex-col items-center gap-3"
            >
              <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main">
                <FaPeopleGroup size={32} />
              </div>
              <span className="line-clamp-2 text-center text-sm font-medium leading-4">
                Gerenciar equipe
              </span>
            </Link>
          )}
        </div>
      </div>
      <div className="absolute left-0 top-0 z-10 h-full w-4 bg-gradient-to-l from-transparent to-background-main" />
      <div className="absolute right-0 top-0 z-10 h-full w-4 bg-gradient-to-r from-transparent to-background-main" />
      {showConfirmationModal && (
        <Modal
          closeModal={handleModalClose}
          isParentClosing={isParentClosing}
          isMobile={isMobile}
        >
          <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
            <div className="flex w-full flex-col justify-between gap-4">
              <div className="flex justify-center pt-2">
                <span className="flex size-12 select-none items-center justify-center rounded-full bg-primary-main text-2xl font-bold">
                  !
                </span>
              </div>
              <span className="py-2 text-center text-xl font-semibold leading-tight">
                A página de clientes está disponível apenas no desktop.
              </span>
              <div className="h-12 w-full self-center">
                <Button
                  text="Voltar"
                  enabled
                  className="w-full text-base font-bold"
                  onClick={() => {
                    handleModalClose()
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}
