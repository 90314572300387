import { useState, type ReactElement } from 'react'

import { errorStore, useCurrentError } from '@/store/error'

import { usePostValidateTickets } from '@/hooks/List'

import { numberToReais } from '@/utils/formatNumber'
import { hasClearance } from '@/utils/rbac'

import { Button } from '@/components/Global/Button'
import { Modal } from '@/components/Global/Modal'
import { TicketSummary } from '@/components/Global/TicketSummary'
import { Checkbox } from '@/components/Global/Checkbox'

import { useCurrentEvent } from '@/store/eventMenu'
import { deviceStore } from '@/store/device'
import { useUserPermissions } from '@/store/user'

import { type IUserData } from '@/types/List'
import { isTruthy } from '@/utils/validation'

interface ListUserInfoModalProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  isLoading?: boolean
  currentUser: IUserData | undefined
  updateState: (currentUser: IUserData, ticketsValidated: number[]) => void
}

export function ListUserInfoModal({
  closeModal,
  handleModalClose,
  isParentClosing,
  currentUser,
  updateState,
}: ListUserInfoModalProps): ReactElement {
  const [ticketsIdsSelected, setTicketsIdsSelected] = useState<number[]>([])

  const { isError, errorMessage } = useCurrentError()
  const { validateTickets, isLoading } = usePostValidateTickets()

  const { showError } = errorStore()
  const { isMobile } = deviceStore()
  const { eventPermissions } = useUserPermissions()

  const { currentEvent } = useCurrentEvent()

  const canEditList = hasClearance(eventPermissions.list, 'EDITOR')

  async function handleButtonOnClick(): Promise<void> {
    const response = await validateTickets(currentEvent!.id, ticketsIdsSelected)

    if (!response.status) {
      showError(
        `Erro ao validar ingresso${ticketsIdsSelected.length > 1 ? 's' : ''}!`,
      )
      return
    }

    updateState(currentUser!, ticketsIdsSelected)

    handleModalClose()
  }

  function handleCheckboxClick(ticketId: number): void {
    const ticketClicked = ticketsIdsSelected.find((id) => ticketId === id)
    if (ticketClicked !== undefined) {
      const removeTicket = ticketsIdsSelected.filter((id) => id !== ticketId)
      setTicketsIdsSelected(removeTicket)
    } else {
      setTicketsIdsSelected([...ticketsIdsSelected, ticketId])
    }
  }

  function handleIsSelected(ticketId: number): boolean {
    const ticketFinded = ticketsIdsSelected.find((id) => id === ticketId)
    if (ticketFinded !== undefined) {
      return true
    }

    return false
  }

  function setIsButtonEnabled(): boolean {
    return currentUser?.tickets.every((ticket) => ticket.isValidated) ?? false
  }

  function selectText(): string {
    if (setIsButtonEnabled()) {
      return 'Ingressos validados'
    } else if (!setIsButtonEnabled() && ticketsIdsSelected.length < 1) {
      return 'Selecione um ingresso'
    } else {
      return 'Validar ingressos'
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        <div className="flex w-full flex-col justify-between gap-4">
          {currentUser !== undefined && (
            <>
              <strong className="break-words text-2xl font-black">
                {currentUser?.firstName} {currentUser?.lastName}
              </strong>
              <div className="flex items-center gap-4 rounded-lg bg-[#f4f4f4] p-3">
                <img
                  src={`${
                    import.meta.env.VITE_S3 as string
                  }/${currentUser?.imageKey}`}
                  alt="Foto perfil"
                  className="aspect-square w-12 min-w-[48px] rounded-full"
                />
                <div className="flex w-[calc(100%-7rem)] grow flex-col">
                  <span className="truncate font-bold text-black">
                    @{currentUser?.username}
                  </span>
                  <span className="truncate text-black">
                    {currentUser?.email}
                  </span>
                </div>
              </div>
            </>
          )}
          <main className="relative">
            <div className="relative flex max-h-56 flex-col gap-2 overflow-scroll py-2">
              {currentUser?.tickets
                .sort((a, b) =>
                  a.isValidated === b.isValidated ? 0 : a.isValidated ? 1 : -1,
                )
                .map((ticket, index) => {
                  const ticketSpecDescription = isTruthy(
                    ticket.batchDescription,
                  )
                    ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                    : ticket.ticketSpecDescription
                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between"
                    >
                      <div
                        className={
                          ticket.isValidated
                            ? 'w-3/4'
                            : canEditList
                              ? 'w-[88%]'
                              : 'w-full'
                        }
                      >
                        <TicketSummary
                          title={ticketSpecDescription}
                          subtitle={`Total ${numberToReais(ticket.price, 2)}`}
                          quantity={1}
                          isInfinite={false}
                          counterVariant="dark"
                          variant="dark"
                        />
                      </div>

                      <div className="w-fit">
                        {ticket.isValidated ? (
                          <div className="flex h-6 w-16 items-center justify-center rounded-lg bg-primary-main px-2 py-1 font-black">
                            <span className="text-[0.625rem]">VALIDADO</span>
                          </div>
                        ) : (
                          canEditList && (
                            <div>
                              <div className="size-6">
                                <Checkbox
                                  onClick={() => {
                                    handleCheckboxClick(ticket.ticketId)
                                  }}
                                  isSelected={handleIsSelected(ticket.ticketId)}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )
                })}
            </div>
            <div className="absolute left-0 top-0 z-50 h-2 w-full bg-gradient-to-b  from-[#ffffff] to-transparent" />
            <div className="absolute bottom-0 left-0 z-50 h-2 w-full bg-gradient-to-b  from-transparent to-[#ffffff]" />
          </main>

          {canEditList && (
            <Button
              className="h-12 w-full"
              onClick={() => {
                void handleButtonOnClick()
              }}
              isLoading={isLoading}
              enabled={selectText() === 'Validar ingressos' && !isLoading}
              text={selectText()}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}
