import useSWR from 'swr'

import { fetcher } from '@/services/axios'

interface GetResponseProps {
  title: string
  imageKey: string
  id: number
  ticketSpecs: Array<{
    id: number
    batchDescription?: string
    ticketSpecDescription: string
  }>
}

export const useGetOrganizationEventTicketSpecs = (
  organizationId: number,
): {
  data: GetResponseProps[] | undefined
  isLoading: boolean
  error: string | undefined
} => {
  const { data, error, isLoading } = useSWR<GetResponseProps[], string>(
    `/organizations/${organizationId}/events/tickets/`,
    fetcher,
  )

  return {
    data,
    isLoading,
    error,
  }
}
