import { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { BsChevronRight } from 'react-icons/bs'
// import { FaInstagram, FaTiktok } from "react-icons/fa";
import { IoMdFemale, IoMdMale } from 'react-icons/io'
import { MdQuestionMark } from 'react-icons/md'

interface CustomerCardProps {
  className?: string
  to: string
  data: {
    name: string
    username: string
    age: number
    gender: 'male' | 'female' | 'unknown'
    profileImageKey: string
    events: Array<{
      id: number
      startDate: string | null
      imageKey: string
    }>
  }
}

export function CustomerCard({
  className,
  to,
  data,
}: CustomerCardProps): ReactElement {
  return (
    <Link
      to={to}
      className={twMerge(
        'flex cursor-pointer rounded-lg bg-dark-black p-3 transition-colors hover:bg-dark-black/90 lg:p-4',
        className,
      )}
    >
      <div className="mr-1 flex w-full min-w-48 max-w-48 items-center gap-2.5 lg:min-w-56 lg:max-w-56 lg:gap-4">
        <img
          src={`${import.meta.env.VITE_S3 as string}/${data.profileImageKey}`}
          alt={`Foto de perfil de @${data.username}`}
          className={'size-14 min-w-14 rounded-full lg:size-16 lg:min-w-16'}
        />
        <div className="truncate">
          <p className="truncate font-bold">{data.name}</p>
          <p className="truncate text-sm">@{data.username}</p>
        </div>
      </div>
      <div className="grid w-full grid-cols-7 gap-1 lg:grid-cols-2 lg:gap-3">
        {/* <div className="flex items-center justify-center gap-2 bg-blue-300">
          <div className="flex size-6 min-w-6 cursor-pointer items-center justify-center rounded-full bg-[#2a2a2a] transition-colors hover:bg-[#2a2a2a]/90">
            <FaInstagram className="size-3" />
          </div>
          <div className="flex size-6 min-w-6 cursor-pointer items-center justify-center rounded-full bg-[#2a2a2a] transition-colors hover:bg-[#2a2a2a]/90">
            <FaTiktok className="size-3" />
          </div>
        </div> */}
        <div className="col-span-5 flex items-center justify-center lg:col-span-1">
          <p>
            <span className="font-semibold">
              {data.age >= 100 ? 'Indefinida' : `${data.age} anos`}
            </span>
          </p>
        </div>
        <div className="col-span-2 flex items-center justify-start lg:col-span-1">
          <p className="hidden lg:block">
            {data.gender === 'male'
              ? 'Masculino'
              : data.gender === 'female'
                ? 'Feminino'
                : 'Indefinido'}
          </p>
          <div className="block lg:hidden">
            {data.gender === 'male' ? (
              <IoMdMale className="size-5 text-dark-dark-gray" />
            ) : data.gender === 'female' ? (
              <IoMdFemale className="size-5 text-dark-dark-gray" />
            ) : (
              <MdQuestionMark className="size-5 text-dark-dark-gray" />
            )}
          </div>
        </div>
      </div>
      <div className="ml-1 flex items-center gap-2 pr-1">
        <div className="flex w-full min-w-28 max-w-28 items-center justify-end gap-1.5 lg:min-w-40 lg:max-w-40">
          <div className="flex items-center justify-center pl-1.5">
            {data.events.slice(0, 3).map((event) => (
              <img
                key={event.id}
                src={`${import.meta.env.VITE_S3 as string}/${event.imageKey}`}
                alt={`Foto de capa do evento.`}
                className={
                  '-ml-1.5 size-7 min-w-7 rounded-md border border-[#181818]'
                }
              />
            ))}
          </div>
          <div className="w-full min-w-8 max-w-8 text-sm tracking-tighter lg:min-w-12 lg:max-w-12">
            {data.events.length > 3 && `+ ${data.events.length - 3}`}
          </div>
        </div>
        <BsChevronRight strokeWidth={0.5} className="size-5" />
      </div>
    </Link>
  )
}
