import { type ReactElement } from 'react'

interface CustomerProfileDetailsCardProps {
  label?: string
  data?: string
}

export function CustomerProfileDetailsCard({
  label,
  data,
}: CustomerProfileDetailsCardProps): ReactElement {
  return (
    <div className="min-w-24 whitespace-nowrap rounded-md bg-dark-black p-3">
      <p className="pb-[5px] text-[0.8125rem] font-light leading-none">
        {label}
      </p>
      <p className="text-[0.9375rem] font-medium leading-none">{data}</p>
    </div>
  )
}
