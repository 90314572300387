import {
  type ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'

import { Spinner } from '@/components/Global/Spinner'
import { CustomerCard } from '@/components/Customers/CustomerCard'
import { CustomersAgeFilter } from '@/components/Customers/CustomersAgeFilter'
import { CustomersSortSelect } from '@/components/Customers/CustomersSortSelect'
import { CustomersEventFilter } from '@/components/Customers/CustomersEventFilter'
import { CustomersSummaryCard } from '@/components/Customers/CustomersSummaryCard'
import { CustomersGenderFilter } from '@/components/Customers/CustomersGenderFilter'
import { CustomersExportDataModal } from '@/components/Customers/CustomersExportDataModal'
import { CustomersSearchFilterInput } from '@/components/Customers/CustomersSearchFilterInput'
import { CustomerAnalyticsWrapper } from '@/components/CustomerAnalyticsWrapper/CustomerAnalyticsWrapper'

import {
  useGetCustomersSummary,
  useGetInfiniteCustomers,
  useGetOrganizationEventTicketSpecs,
} from '@/hooks/Customers/'

import { wrapperStore } from '@/store/wrapper'
import { useCurrentOrganization } from '@/store/organization'

import { api } from '@/services/axios'
import { calculateAge } from '@/utils/formatData'

import { LuArrowLeft, LuArrowRight, LuCheck } from 'react-icons/lu'

type Filter = 'query' | 'age' | 'gender'

const TAKE = 50
const DEBOUNCE_MS = 1000

export function Customers(): ReactElement {
  const [sort, setSort] = useState<'name' | 'age' | 'engagement'>('name')
  const [ascending, setAscending] = useState(true)
  const [queryInput, setQueryInput] = useState('')
  const [query, setQuery] = useState('')
  const [showMultipleThumbs, setShowMultipleThumbs] = useState(true)
  const [ageRangeInput, setAgeRangeInput] = useState([18, 70])
  const [ageRange, setAgeRange] = useState([18, 70])
  const [genderFilter, setGenderFilter] = useState<
    Array<'male' | 'female' | 'unknown'>
  >([])
  const [showEventSelectionModal, setShowEventSelectionModal] = useState(false)
  const [ticketSpecIds, setTicketSpecIds] = useState<number[]>([])
  const [selectedTicketSpecIds, setSelectedTicketSpecIds] = useState<number[]>(
    [],
  )
  const [openEventsIds, setOpenEventsIds] = useState<number[]>([])
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [showExportDataModal, setShowExportDataModal] = useState(false)
  const [exportSuccessful, setExportSuccessful] = useState(false)
  const [exportWithoutFiltersIsLoading, setExportWithoutFiltersIsLoading] =
    useState(false)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(true)

  const scrollDiv = useRef<HTMLDivElement | null>(null)
  const observerRef = useRef<HTMLDivElement | null>(null)

  const { setHeaderButton } = wrapperStore()
  const { currentOrganization } = useCurrentOrganization()

  const queryParams = {
    q: query,
    sortBy: sort,
    order: ascending ? 'asc' : 'desc',
    gender: genderFilter,
    ageEq: ageRange.length === 1 ? ageRange[0] : undefined,
    ageGte:
      ageRange.length === 2 && ageRange[0] !== 11 ? ageRange[0] : undefined,
    ageLte:
      ageRange.length === 2 && ageRange[1] !== 100 ? ageRange[1] : undefined,
    ticketSpecIds,
  }
  const organizationId: number = currentOrganization?.organizer
    .organizationId as number
  const { data: customerSummary } = useGetCustomersSummary(
    organizationId,
    queryParams,
  )
  const { data: organizationEvents } =
    useGetOrganizationEventTicketSpecs(organizationId)
  const { data, size, setSize, isValidating, isLoading } =
    useGetInfiniteCustomers(organizationId, queryParams, TAKE)

  const items = data !== undefined ? data.flatMap((page) => page) : []
  const hasMore = data === undefined || data[data.length - 1]?.length === TAKE

  const handleClearFilters = (): void => {
    setGenderFilter([])
    setAgeRangeInput([18, 70])
    setAgeRange([18, 70])
    setShowMultipleThumbs(true)
    setQueryInput('')
    setQuery('')
    setTicketSpecIds([])
    setSelectedTicketSpecIds([])
  }

  const handleExportDataButtonClick = useCallback(async (): Promise<void> => {
    const hasFiltersApplied =
      queryInput.trim().length > 0 ||
      ageRangeInput.length === 1 ||
      (ageRangeInput.length === 2 &&
        (ageRangeInput[0] !== 11 || ageRangeInput[1] !== 100)) ||
      genderFilter.length > 0 ||
      ticketSpecIds.length > 0

    if (hasFiltersApplied) {
      setShowExportDataModal(true)
    } else {
      setExportWithoutFiltersIsLoading(true)
      try {
        await api.get(
          `/admin/analytics/organizations/${organizationId}/customers-report`,
        )
        setExportSuccessful(true)
      } catch (error) {
      } finally {
        setExportWithoutFiltersIsLoading(false)
      }
    }
  }, [queryInput, ageRangeInput, genderFilter, ticketSpecIds])

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries
      if (entry.isIntersecting && hasMore && !isLoading && !isValidating) {
        void setSize(size + 1)
      }
    },
    [size, hasMore, setSize, isLoading, isValidating],
  )

  const handleScroll = (event: React.UIEvent<HTMLElement>): void => {
    setShowLeftArrow(event.currentTarget.scrollLeft > 0)
    setShowRightArrow(
      event.currentTarget.scrollLeft <
        event.currentTarget.scrollWidth - event.currentTarget.clientWidth,
    )
  }

  const handleScrollClick = (direction: 'left' | 'right'): void => {
    if (scrollDiv.current === null) return
    const currentScrollPosition = scrollDiv.current.scrollLeft
    const offset = 256
    const targetScrollPosition =
      direction === 'left'
        ? currentScrollPosition - offset
        : currentScrollPosition + offset
    scrollDiv.current.scrollTo({
      left: targetScrollPosition,
      behavior: 'smooth',
    })
  }

  const getAppliedFilters = (): Array<{
    label: string
    value: Filter
  }> => {
    const appliedFilters = []

    if (queryInput.trim().length > 0) {
      appliedFilters.push({ label: 'Nome', value: 'query' as Filter })
    }

    if (
      ageRangeInput.length === 1 ||
      (ageRangeInput.length === 2 &&
        (ageRangeInput[0] !== 11 || ageRangeInput[1] !== 100))
    ) {
      appliedFilters.push({ label: 'Idade', value: 'age' as Filter })
    }

    if (queryParams.gender.length > 0) {
      appliedFilters.push({ label: 'Gênero', value: 'gender' as Filter })
    }

    return appliedFilters
  }

  useEffect(() => {
    setHeaderButton(
      <div className="h-8 w-fit">
        <button
          disabled={exportWithoutFiltersIsLoading}
          onClick={exportSuccessful ? undefined : handleExportDataButtonClick}
          className={twMerge(
            'relative flex h-full w-[132px] items-center justify-center rounded-full bg-primary-main px-4 text-sm font-bold text-black transition-colors disabled:cursor-not-allowed disabled:bg-[#A2A2A2] disabled:text-[#656565]',
            exportSuccessful ? 'cursor-default' : 'hover:bg-primary-main/90',
          )}
        >
          {exportWithoutFiltersIsLoading ? (
            <Spinner
              borderWidth="border-4"
              borderColor="border-background-main/50"
              bottomBorderColor="border-b-background-main"
              width="w-5"
              height="h-5"
            />
          ) : exportSuccessful ? (
            <LuCheck size={24} />
          ) : (
            'Exportar dados'
          )}
        </button>
      </div>,
    )
  }, [
    handleExportDataButtonClick,
    exportWithoutFiltersIsLoading,
    exportSuccessful,
  ])

  useEffect(() => {
    const handler = setTimeout(() => {
      setQuery(queryInput.trim())
      setAgeRange(ageRangeInput)
    }, DEBOUNCE_MS)

    return () => {
      clearTimeout(handler)
    }
  }, [queryInput, ageRangeInput])

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 1.0,
    })
    if (observerRef.current !== null) observer.observe(observerRef.current)
    return () => {
      observer.disconnect()
    }
  }, [handleObserver])

  return (
    <CustomerAnalyticsWrapper>
      <div className="flex size-full flex-col gap-4 p-0 pt-7 xl:flex-row xl:justify-center xl:gap-6 xl:p-4 xl:pb-0">
        <div className="relative w-full xl:w-auto">
          <div className="flex size-full justify-start gap-4 scroll-smooth xl:px-0">
            <div
              ref={scrollDiv}
              onScroll={handleScroll}
              className="xl:min-h-none flex h-full max-h-[158px] min-h-[158px] w-auto gap-2 overflow-x-scroll px-4 xl:max-h-none xl:max-w-64 xl:flex-col xl:gap-3 xl:overflow-visible xl:px-0"
            >
              <div className="flex w-full min-w-64 flex-col gap-2 xl:gap-3">
                <CustomersSortSelect
                  sort={sort}
                  setSort={setSort}
                  ascending={ascending}
                  setAscending={setAscending}
                />
                <CustomersSearchFilterInput
                  value={queryInput}
                  setValue={setQueryInput}
                />
              </div>
              <CustomersAgeFilter
                ageRange={ageRangeInput}
                setAgeRange={setAgeRangeInput}
                showMultipleThumbs={showMultipleThumbs}
                setShowMultipleThumbs={setShowMultipleThumbs}
              />
              <CustomersGenderFilter
                selectedGenders={genderFilter}
                setSelectedGenders={setGenderFilter}
              />
              <div className="flex w-full min-w-[212px] flex-col justify-between gap-2 pb-0 xl:min-w-64 xl:justify-start xl:gap-3 xl:pb-4">
                {organizationEvents !== undefined && (
                  <CustomersEventFilter
                    showEventSelectionModal={showEventSelectionModal}
                    setShowEventSelectionModal={setShowEventSelectionModal}
                    isParentClosing={isParentClosing}
                    setIsParentClosing={setIsParentClosing}
                    events={organizationEvents}
                    openEventsIds={openEventsIds}
                    setOpenEventsIds={setOpenEventsIds}
                    setSelectedTicketSpecIds={setSelectedTicketSpecIds}
                    selectedTicketSpecIds={selectedTicketSpecIds}
                    ticketSpecIds={ticketSpecIds}
                    setTicketSpecIds={setTicketSpecIds}
                  />
                )}
                <button
                  onClick={handleClearFilters}
                  className="w-full cursor-pointer rounded-full bg-dark-black p-2.5 text-center text-sm font-semibold transition-colors hover:bg-[#202020]"
                >
                  Limpar filtros
                </button>
              </div>
            </div>
          </div>
          <div
            className={twMerge(
              'pointer-events-none absolute bottom-0 right-0 top-0 z-20 items-center justify-center pr-3 xl:hidden',
              showRightArrow ? 'flex' : 'hidden',
            )}
          >
            <button
              onClick={() => {
                handleScrollClick('right')
              }}
              className="pointer-events-auto rounded-full border border-[#eaeaea] bg-white p-[3px] text-dark-black transition-colors hover:bg-[#eaeaea]"
            >
              <LuArrowRight size={16} />
            </button>
          </div>
          <div
            className={twMerge(
              'pointer-events-none absolute bottom-0 left-0 top-0 z-20 items-center justify-center pl-3 xl:hidden',
              showLeftArrow ? 'flex' : 'hidden',
            )}
          >
            <button
              onClick={() => {
                handleScrollClick('left')
              }}
              className="pointer-events-auto rounded-full border border-[#eaeaea] bg-white p-[3px] text-dark-black transition-colors hover:bg-[#eaeaea]"
            >
              <LuArrowLeft size={16} />
            </button>
          </div>
          <div className="absolute left-0 top-0 z-10 h-full w-4 bg-gradient-to-l from-transparent to-[#2a2a2a] xl:hidden" />
          <div className="absolute right-0 top-0 z-10 h-full w-4 bg-gradient-to-r from-transparent to-[#2a2a2a] xl:hidden" />
        </div>
        <div className="w-full max-w-4xl p-4 xl:p-0">
          {customerSummary !== undefined ? (
            <CustomersSummaryCard
              isLoading={false}
              data={{
                customerCount: customerSummary.numberOfCustomers,
                averageAge: customerSummary.averageAge,
                predominantGender:
                  customerSummary.predominantGender === 'male'
                    ? 'male'
                    : customerSummary.predominantGender === 'female'
                      ? 'female'
                      : null,
              }}
            />
          ) : (
            <CustomersSummaryCard isLoading />
          )}
          {isLoading ? (
            <div className="mb-4 mt-7 space-y-3">
              {Array.from({ length: 6 }, (_, index) => (
                <div
                  key={index}
                  className="flex h-20 animate-pulse cursor-pointer rounded-lg bg-dark-black xl:h-24"
                />
              ))}
            </div>
          ) : items !== undefined ? (
            <div
              className={twMerge(
                'mb-4 mt-7 space-y-3',
                items.length !== 0 && 'pb-3',
              )}
            >
              {items.length === 0 ? (
                <div className="py-40 text-center">
                  <p>Nenhum cliente foi encontrado.</p>
                </div>
              ) : (
                items.map((customer) => (
                  <CustomerCard
                    key={customer.id}
                    to={customer.username}
                    data={{
                      name: customer.firstName + ' ' + customer.lastName,
                      username: customer.username,
                      age: calculateAge(customer.birthdate),
                      gender:
                        customer.gender === 'male'
                          ? 'male'
                          : customer.gender === 'female'
                            ? 'female'
                            : 'unknown',
                      profileImageKey: customer.profileImageKey,
                      events: customer.events,
                    }}
                  />
                ))
              )}
            </div>
          ) : null}
          <div ref={observerRef} style={{ height: 1 }} />
          {!isLoading && isValidating && (
            <div className="mb-5 flex w-full items-center justify-center gap-4 pb-6">
              <Spinner
                borderWidth="border-4"
                borderColor="border-primary-main/50"
                bottomBorderColor="border-b-primary-main"
              />
            </div>
          )}
        </div>
      </div>
      {showExportDataModal && (
        <CustomersExportDataModal
          queryParams={queryParams}
          appliedFilters={getAppliedFilters()}
          setShowModal={setShowExportDataModal}
          isParentClosing={isParentClosing}
          setIsParentClosing={setIsParentClosing}
          events={organizationEvents}
          ticketSpecIds={ticketSpecIds}
        />
      )}
    </CustomerAnalyticsWrapper>
  )
}
