import { type ReactElement, useState, useRef, useEffect } from 'react'
import { isToday, isYesterday } from 'date-fns'
import { useParams } from 'react-router-dom'

import { BalanceCard } from '@/components/Finance/BalanceCard'
import { WithdrawalCard } from '@/components/Finance/WithdrawalCard'
import { WithdrawalModal } from '@/components/Finance/WithdrawalModal'
import { CreateWithdrawalSelectModal } from '@/components/Finance/CreateWithdrawalSelectModal'
import { Button } from '@/components/Global/Button'
import { CreateInternalWithdrawalModal } from '@/components/Finance/CreateInternalWithdrawalModal'
import { CreateExternalWithdrawalModal } from '@/components/Finance/CreateExternalWithdrawalModal'

import { type IFinance, type IWithdrawal } from '@/types/Finance'

import { isTruthy } from '@/utils/validation'
import { dateToFormat } from '@/utils/formatData'
import { hasClearance } from '@/utils/rbac'

import { useUserPermissions } from '@/store/user'

import { useGetWithdrawalsDataByEventAlias } from '@/hooks/Finance'

export function Finance(): ReactElement {
  const [groupedWithdrawals, setGroupedWithdrawals] = useState<
    Record<string, IWithdrawal[]>
  >({})
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [currentWithdrawal, setCurrentWithdrawal] = useState<IWithdrawal>()
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false)
  const [showCreateWithdrawalModal, setShowCreateWithdrawalModal] =
    useState(false)
  const [
    showCreateInternalWithdrawalModal,
    setShowCreateInternalWithdrawalModal,
  ] = useState(false)
  const [
    showCreateExternalWithdrawalModal,
    setShowCreateExternalWithdrawalModal,
  ] = useState(false)

  const { alias } = useParams()

  const { eventPermissions } = useUserPermissions()

  const { financeDataByEventAlias, refresh } =
    useGetWithdrawalsDataByEventAlias(alias!)

  const balance =
    (financeDataByEventAlias?.revenue ?? 0) *
      (1 + (financeDataByEventAlias?.cashback ?? 0)) -
    (financeDataByEventAlias?.withdrawals ?? 0)

  const mainDivRef = useRef<HTMLDivElement>(null)

  const canEditFinance = hasClearance(eventPermissions.finance, 'EDITOR')

  function splitWithdrawalsByDate(
    rawFinanceData: IFinance,
  ): Record<string, IWithdrawal[]> {
    const tempGroupedWithdrawals: Record<string, IWithdrawal[]> = {}

    const rawWithdrawals = rawFinanceData.withdrawalItems

    const sortedWithdrawals = rawWithdrawals.sort((a, b) => {
      return new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
    })

    for (const withdrawal of sortedWithdrawals) {
      const date = withdrawal.createdAt.split('T')[0]

      if (!isTruthy(tempGroupedWithdrawals[date])) {
        tempGroupedWithdrawals[date] = []
      }
      tempGroupedWithdrawals[date].push(withdrawal)
    }

    return tempGroupedWithdrawals
  }

  useEffect(() => {
    if (isTruthy(financeDataByEventAlias))
      setGroupedWithdrawals(splitWithdrawalsByDate(financeDataByEventAlias!))
  }, [financeDataByEventAlias])

  return (
    <div ref={mainDivRef} className="flex size-full flex-col">
      <main className="flex flex-col gap-4 p-4 pb-24">
        <BalanceCard
          revenue={financeDataByEventAlias?.revenue ?? 0}
          withdrawal={financeDataByEventAlias?.withdrawals ?? 0}
          cashback={financeDataByEventAlias?.cashback ?? 0}
        />
        {Object.keys(groupedWithdrawals ?? {}).map((date) => {
          const withdrawalDate = new Date(`${date}T12:00:00.000Z`)

          const formattedDate = isToday(withdrawalDate)
            ? 'Hoje'
            : isYesterday(withdrawalDate)
              ? 'Ontem'
              : dateToFormat(date)

          return (
            <div key={date} className="flex w-full flex-col gap-2">
              <span className="font-bold">{formattedDate}</span>
              <div className="columns-1 desktop:columns-2">
                {groupedWithdrawals[date].map((withdrawal) => {
                  return (
                    <div
                      className="break-inside-avoid-column"
                      key={withdrawal.id}
                    >
                      <WithdrawalCard
                        key={withdrawal.id}
                        title={withdrawal.title}
                        status={withdrawal.status}
                        amount={withdrawal.amount}
                        onClick={() => {
                          setCurrentWithdrawal(withdrawal)
                          setShowWithdrawalModal(true)
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </main>
      {canEditFinance && (
        <footer className="fixed bottom-4 left-1/2 z-10 flex h-12 w-fit min-w-[300px] -translate-x-1/2 items-center justify-center px-4 desktop:absolute">
          <Button
            enabled={true}
            text="Solicitar saque"
            onClick={() => {
              setShowCreateWithdrawalModal(true)
            }}
          ></Button>
        </footer>
      )}

      {showWithdrawalModal && (
        <WithdrawalModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowWithdrawalModal, 400, false)
          }}
          handleModalClose={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowWithdrawalModal, 400, false)
            void refresh()
          }}
          withdrawal={currentWithdrawal!}
        />
      )}
      {showCreateWithdrawalModal && (
        <CreateWithdrawalSelectModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowCreateWithdrawalModal, 400, false)
          }}
          handleModalClose={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowCreateWithdrawalModal, 400, false)
          }}
          openCreateInternalModal={() => {
            setShowCreateInternalWithdrawalModal(true)
          }}
          openCreateExternalModal={() => {
            setShowCreateExternalWithdrawalModal(true)
          }}
          hasInternalWithdrawalPending={
            financeDataByEventAlias?.withdrawalItems.some(
              (withdrawal) =>
                withdrawal.status === 'PENDING' &&
                withdrawal.type === 'INTERNAL',
            ) ?? false
          }
        />
      )}
      {showCreateInternalWithdrawalModal && (
        <CreateInternalWithdrawalModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowCreateInternalWithdrawalModal, 400, false)
          }}
          handleModalClose={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowCreateInternalWithdrawalModal, 400, false)
            void refresh()
          }}
          alias={alias!}
          balance={balance}
        />
      )}
      {showCreateExternalWithdrawalModal && (
        <CreateExternalWithdrawalModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowCreateExternalWithdrawalModal, 400, false)
          }}
          handleModalClose={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowCreateExternalWithdrawalModal, 400, false)
            void refresh()
          }}
          alias={alias!}
          balance={balance}
        />
      )}
    </div>
  )
}
