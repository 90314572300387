import { forwardRef, useRef, useState, type ReactElement } from 'react'
import { GoSearch, GoX } from 'react-icons/go'
import { twMerge } from 'tailwind-merge'
import { DebounceInput } from '../DebounceInput'

interface DebounceSearchBarProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string
  onCancel: () => void
  onChangeText: (value: string) => void
}

const DebounceSearchBar = forwardRef<HTMLInputElement, DebounceSearchBarProps>(
  (
    { value, className, onCancel, onChangeText, ...props },
    ref,
  ): ReactElement => {
    const [input, setInput] = useState(value)
    const cancelButtonRef = useRef<HTMLButtonElement | null>(null)

    return (
      <div
        className={twMerge(
          'z-0 flex h-12 w-full items-center justify-between gap-2 rounded-lg border-2 border-[#7c7c7c] bg-[#2a2a2a] px-3 py-2.5 text-white [&:has(:focus:not(button))]:border-[#defe76]',
          className,
        )}
      >
        <div className="flex w-full items-center gap-2">
          <GoSearch className="size-5" strokeWidth={1} />
          <DebounceInput
            className="w-full border-none bg-transparent text-sm outline-none"
            ref={ref}
            value={input}
            onValueChange={setInput}
            onDebounce={onChangeText}
            {...props}
          />
        </div>
        {input.length > 0 && (
          <button
            ref={cancelButtonRef}
            onClick={() => {
              cancelButtonRef.current?.blur()
              setInput('')
              onCancel()
            }}
            className="cursor-pointer rounded-full p-0.5 transition-colors hover:bg-[#343434]"
          >
            <GoX className="size-5" strokeWidth={0.5} />
          </button>
        )}
      </div>
    )
  },
)

DebounceSearchBar.displayName = 'DebounceSearchBar'

export { DebounceSearchBar }
