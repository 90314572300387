import { compareAsc, parseISO } from 'date-fns'

import { type Event } from '@/types/Organization'
import { isEventFinished } from '@/utils/isEventFinished'

export function categorizeEventsByDate(events: Event[]): {
  pastEvents: Event[]
  futureEvents: Event[]
} {
  const orderedEvents = events.sort((a, b) =>
    compareAsc(parseISO(a.date), parseISO(b.date)),
  )
  const pastEvents: Event[] = []
  const futureEvents: Event[] = []

  for (const event of orderedEvents) {
    if (isEventFinished(event.endDate)) {
      pastEvents.push(event)
    } else {
      futureEvents.push(event)
    }
  }

  return {
    pastEvents,
    futureEvents,
  }
}
