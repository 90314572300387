import { type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'
import { Checkbox } from '@/components/Global/Checkbox'

import { deviceStore } from '@/store/device'

import { LuChevronDown, LuPencil, LuXCircle } from 'react-icons/lu'
import { IoAddCircleOutline } from 'react-icons/io5'
import { isTruthy } from '@/utils/validation'
import { twMerge } from 'tailwind-merge'

interface CustomersEventFilterProps {
  showEventSelectionModal: boolean
  setShowEventSelectionModal: (value: boolean) => void
  isParentClosing: boolean
  setIsParentClosing: (value: boolean) => void
  events: Array<{
    title: string
    imageKey: string
    id: number
    ticketSpecs: Array<{
      id: number
      batchDescription?: string
      ticketSpecDescription: string
    }>
  }>
  openEventsIds: number[]
  setOpenEventsIds: (value: number[]) => void
  setSelectedTicketSpecIds: React.Dispatch<React.SetStateAction<number[]>>
  selectedTicketSpecIds: number[]
  ticketSpecIds: number[]
  setTicketSpecIds: (value: number[]) => void
}

export function CustomersEventFilter({
  showEventSelectionModal,
  setShowEventSelectionModal,
  isParentClosing,
  setIsParentClosing,
  events,
  openEventsIds,
  setOpenEventsIds,
  setSelectedTicketSpecIds,
  selectedTicketSpecIds,
  ticketSpecIds,
  setTicketSpecIds,
}: CustomersEventFilterProps): ReactElement {
  const { isMobile } = deviceStore()

  const handleOpenModal = (): void => {
    setOpenEventsIds([])
    setSelectedTicketSpecIds(ticketSpecIds)
    setShowEventSelectionModal(true)
  }

  const eventsWithFilteredTicketSpecs = events
    .map((event) => {
      const matchingTicketSpecs = event.ticketSpecs.filter((ticketSpec) =>
        ticketSpecIds.includes(ticketSpec.id),
      )
      return {
        event,
        numberOfTicketSpecs: matchingTicketSpecs.length,
      }
    })
    .filter(({ numberOfTicketSpecs }) => numberOfTicketSpecs > 0)

  return (
    <>
      <div className="flex-1 shrink-0 rounded-lg bg-dark-black xl:flex-auto">
        <div className="flex h-full flex-col overflow-hidden">
          <div className="mb-2 flex items-center justify-between p-3 pb-0 pr-2">
            <p className="text-sm font-semibold">Eventos</p>
            <AnimatePresence initial={false}>
              {eventsWithFilteredTicketSpecs.length > 0 && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key="filter-edit-toolbar"
                  className="flex"
                >
                  <button
                    onClick={handleOpenModal}
                    className="rounded-full p-[3px] transition-colors hover:text-primary-main"
                  >
                    <LuPencil size={16} />
                  </button>
                  <button
                    onClick={() => {
                      setTicketSpecIds([])
                      setSelectedTicketSpecIds([])
                    }}
                    className="rounded-full p-[3px] transition-colors hover:text-primary-main"
                  >
                    <LuXCircle size={16} />
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div
            className={twMerge(
              'flex flex-col justify-center px-3 xl:pb-3',
              eventsWithFilteredTicketSpecs.length !== 0 && 'flex-1',
            )}
          >
            {eventsWithFilteredTicketSpecs.length === 0 ? (
              <button
                onClick={handleOpenModal}
                className="flex w-full items-center justify-between gap-2 rounded bg-background-main p-2 text-sm transition-colors hover:bg-[#202020] xl:mt-1"
              >
                Selecionar eventos
                <IoAddCircleOutline className="size-5" />
              </button>
            ) : (
              <div className="relative h-full xl:max-h-[7.5rem]">
                <ul
                  className={twMerge(
                    'flex h-full max-h-14 flex-wrap items-start gap-1 overflow-y-scroll xl:block xl:max-h-[7.5rem] xl:space-y-1.5',
                    eventsWithFilteredTicketSpecs.length >= 4 && 'pb-2 pt-1',
                  )}
                >
                  {eventsWithFilteredTicketSpecs.map(
                    ({ event, numberOfTicketSpecs }) => (
                      <li key={event.id} className="flex items-center gap-2">
                        <img
                          className="size-7 rounded-sm"
                          src={`${import.meta.env.VITE_S3 as string}/${event.imageKey}`}
                          alt={`Foto de capa do evento "${event.title}".`}
                        />
                        <p className="hidden truncate text-xs xl:block">
                          {event.title}{' '}
                          {numberOfTicketSpecs !== event.ticketSpecs.length &&
                            `(${numberOfTicketSpecs} de ${event.ticketSpecs.length})`}
                        </p>
                      </li>
                    ),
                  )}
                </ul>
                <div
                  className={twMerge(
                    'absolute top-0 h-1 w-full bg-gradient-to-t from-transparent to-dark-black',
                    eventsWithFilteredTicketSpecs.length < 4 && 'hidden',
                  )}
                />
                <div
                  className={twMerge(
                    'absolute bottom-0 h-2 w-full bg-gradient-to-b from-transparent to-dark-black',
                    eventsWithFilteredTicketSpecs.length < 4 && 'hidden',
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {showEventSelectionModal && (
        <Modal
          className="w-full max-w-md xl:max-w-3xl"
          closeModal={() => {
            setOpenEventsIds([])
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowEventSelectionModal, 400, false)
          }}
          isParentClosing={isParentClosing}
          isMobile={isMobile}
        >
          <div className="h-fit w-full p-1.5 text-dark-black">
            <h2 className="mb-4 text-2xl font-bold">Eventos</h2>
            <div className="relative max-h-[432px]">
              <div className="max-h-[432px] overflow-scroll">
                <div className="grid grid-cols-1 gap-4 pb-4 xl:grid-cols-2">
                  {events.map((event) =>
                    event.ticketSpecs.length > 0 ? (
                      <div key={event.id}>
                        <button
                          data-state={
                            openEventsIds.includes(event.id) ? 'open' : 'closed'
                          }
                          onClick={() => {
                            openEventsIds.includes(event.id)
                              ? setOpenEventsIds(
                                  openEventsIds.filter((id) => id !== event.id),
                                )
                              : setOpenEventsIds([...openEventsIds, event.id])
                          }}
                          className="flex w-full items-center justify-between gap-2 rounded-xl bg-dark-light-gray p-2.5 transition-colors hover:bg-[#efefef] [&[data-state=open]_svg]:rotate-180"
                        >
                          <div className="flex items-center gap-3 text-left">
                            <img
                              className="size-16 rounded-md"
                              src={`${import.meta.env.VITE_S3 as string}/${event.imageKey}`}
                              alt={`Foto de capa do evento "${event.title}".`}
                            />
                            <p className="font-bold">{event.title}</p>
                          </div>
                          <div className="my-auto flex items-center justify-center">
                            <LuChevronDown className="size-6 shrink-0 text-dark-dark-gray transition-transform duration-200" />
                          </div>
                        </button>
                        <AnimatePresence>
                          {openEventsIds.includes(event.id) && (
                            <motion.div
                              className="px-1.5"
                              initial={{ height: 0, opacity: 0 }}
                              animate={{
                                height: 'auto',
                                opacity: 1,
                                transition: {
                                  height: {
                                    duration: 0.2,
                                    ease: [0.42, 0, 0.58, 1],
                                  },
                                  opacity: { duration: 0.2, delay: 0.2 },
                                },
                              }}
                              exit={{
                                height: 0,
                                opacity: 0,
                                transition: {
                                  opacity: { duration: 0.2 },
                                  height: {
                                    duration: 0.2,
                                    delay: 0.2,
                                    ease: [0.42, 0, 0.58, 1],
                                  },
                                },
                              }}
                            >
                              <div className="flex justify-between pt-3 font-medium text-dark-dark-gray">
                                <button
                                  onClick={() => {
                                    setSelectedTicketSpecIds((prev) => {
                                      const otherSelectedTicketSpecs =
                                        prev.filter(
                                          (id) =>
                                            !event.ticketSpecs
                                              .map(
                                                (ticketSpec) => ticketSpec.id,
                                              )
                                              .includes(id),
                                        )
                                      return [
                                        ...otherSelectedTicketSpecs,
                                        ...event.ticketSpecs.map(
                                          (ticketSpec) => ticketSpec.id,
                                        ),
                                      ]
                                    })
                                  }}
                                >
                                  Selecionar todos
                                </button>
                                <button
                                  onClick={() => {
                                    setSelectedTicketSpecIds((prev) =>
                                      prev.filter(
                                        (id) =>
                                          !event.ticketSpecs
                                            .map((ticketSpec) => ticketSpec.id)
                                            .includes(id),
                                      ),
                                    )
                                  }}
                                >
                                  Limpar
                                </button>
                              </div>
                              <ul className="mt-4 space-y-4 pb-2 font-medium">
                                {event.ticketSpecs.map((ticketSpec) => (
                                  <li
                                    key={ticketSpec.id}
                                    className="flex items-center justify-between"
                                  >
                                    <span>
                                      {isTruthy(ticketSpec.batchDescription)
                                        ? `${ticketSpec.batchDescription} - ${ticketSpec.ticketSpecDescription}`
                                        : ticketSpec.ticketSpecDescription}
                                    </span>
                                    <div className="size-5">
                                      <Checkbox
                                        onChange={() => {
                                          setSelectedTicketSpecIds((prev) =>
                                            prev.includes(ticketSpec.id)
                                              ? prev.filter(
                                                  (id) => id !== ticketSpec.id,
                                                )
                                              : [...prev, ticketSpec.id],
                                          )
                                        }}
                                        isSelected={selectedTicketSpecIds.includes(
                                          ticketSpec.id,
                                        )}
                                      />
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    ) : null,
                  )}
                </div>
              </div>
              <div className="absolute bottom-0 h-4 w-full bg-gradient-to-b from-transparent to-white" />
            </div>
            <div className="pt-4">
              <Button
                enabled={
                  selectedTicketSpecIds.length > 0 || ticketSpecIds.length > 0
                }
                className="mx-auto max-w-80 py-3"
                text="Aplicar seleção"
                onClick={() => {
                  setTicketSpecIds(selectedTicketSpecIds)

                  setOpenEventsIds([])
                  setIsParentClosing(true)
                  setTimeout(setIsParentClosing, 400, false)
                  setTimeout(setShowEventSelectionModal, 400, false)
                }}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
