import { useEffect, type ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { compareAsc, compareDesc, parseISO } from 'date-fns'

import { organizationStore } from '@/store/organization'
import { eventMenuStore } from '@/store/eventMenu'
import { grantStore } from '@/store/grant'
import { deviceStore } from '@/store/device'
import { homeStore } from '@/store/home'
import { updateEventStore } from '@/store/updateEvent'
import { useUserPermissions } from '@/store/user'
import { wrapperStore } from '@/store/wrapper'

import { HomeHeader } from '@/components/Home/HomeHeader'
import { HomeSectionMobile } from '@/components/Home/HomeSection/HomeSectionMobile'
import { OrganizationsModal } from '@/components/Home/OrganizationsModal'
import { InviteProducerModal } from '@/components/Home/InviteProducerModal'
import { HomeIconMenu } from '@/components/Home/HomeMenu/HomeIconMenu'
import { HomeBalance } from '@/components/Home/HomeMenu/HomeBalance'
import { TeamCard } from '@/components/Home/TeamCard'
import { HomeSectionDesktop } from '@/components/Home/HomeSection/HomeSectionDesktop'
import { Button } from '@/components/Global/Button'

import { categorizeEventsByDate } from '@/utils/categorizeEvents'
import { hasClearance } from '@/utils/rbac'
import { Loading } from '@/components/Global/Loading/Loading'

export function Home(): ReactElement {
  const [showTeamsModal, setShowTeamsModal] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)

  const { reset: resetEventMenuStore } = eventMenuStore()
  const { reset: resetGrantStore } = grantStore()
  const { isMobile } = deviceStore()
  const { showInviteProducerModal, setShowInviteProducerModal } = homeStore()
  const { organizationPermissions } = useUserPermissions()
  const { updateAnyState } = updateEventStore()
  const { currentOrganization, allOrganizations } = organizationStore()
  const {
    setShowSideBarOrganizations,
    setShowSideBarEvents,
    setShowSideBarEvent,
    showSideBarOrganizations,
  } = wrapperStore()

  const navigate = useNavigate()

  const canViewBalance = hasClearance(organizationPermissions.finance, 'VIEWER')
  const canViewTeam = hasClearance(organizationPermissions.access, 'VIEWER')
  const canCreateEvent = hasClearance(
    organizationPermissions.eventCreation,
    true,
  )

  const approvedEvents =
    currentOrganization?.events.filter((e) => e.status === 'APPROVED') ?? []
  const pendingApprovalEvents =
    currentOrganization?.events.filter((e) => e.status === 'PENDING') ?? []
  const rejectedEvents =
    currentOrganization?.events.filter((e) => e.status === 'REJECTED') ?? []
  const { pastEvents, futureEvents } = categorizeEventsByDate(approvedEvents)

  const allEvents = [
    ...pastEvents,
    ...futureEvents,
    ...pendingApprovalEvents,
    ...rejectedEvents,
  ]

  useEffect(() => {
    resetEventMenuStore()
  }, [])

  function handleOnClick(): void {
    if (!showTeamsModal) {
      setIsParentClosing(false)
      setShowTeamsModal(true)
    }
  }

  function calculateOrganizationBalance(): number {
    const events = currentOrganization?.events ?? []

    const balance = events.reduce((acc, event) => {
      return acc + (1 + event.cashback) * event.revenue - event.withdrawals
    }, 0)

    return balance
  }

  // Reset stores
  useEffect(() => {
    // Grant
    resetGrantStore()
  }, [])

  useEffect(() => {
    if (isParentClosing) {
      setTimeout(setIsParentClosing, 200, false)
    }
  }, [isParentClosing])

  useEffect(() => {
    if (allOrganizations === null || allOrganizations === undefined) return
    if (allOrganizations?.length === 0) {
      setShowSideBarOrganizations(false)
      setShowSideBarEvents(false)
      setShowSideBarEvent(false)
    } else {
      setShowSideBarOrganizations(true)
      setShowSideBarEvents(true)
    }
  }, [allOrganizations, showSideBarOrganizations])

  if (allOrganizations === null) return <Loading />

  if (allOrganizations?.length === 0) {
    return (
      <div className="flex flex-col items-center gap-8">
        <span className="text-2xl font-black">
          Você não está conectado a nenhuma organização.
        </span>

        <div className="h-12 w-full max-w-[300px]">
          <Button
            enabled
            text="Criar minha organização"
            onClick={() => {
              navigate('/create-organization')
            }}
          />
        </div>
      </div>
    )
  }

  if (isMobile)
    return (
      <>
        <div className="flex min-h-full w-full flex-col overflow-y-scroll bg-background-main">
          <HomeHeader handleOnClick={handleOnClick} />
          <div className="flex flex-col gap-2 pb-2">
            {canViewBalance && (
              <div className="px-4">
                <HomeBalance balance={calculateOrganizationBalance()} />
              </div>
            )}
            <HomeIconMenu
              setShowInviteProducerModal={setShowInviteProducerModal}
            />
          </div>
          <div className="flex flex-col gap-8 p-4">
            {[...pendingApprovalEvents, ...rejectedEvents].length > 0 && (
              <HomeSectionMobile
                title="Eventos desativados"
                events={[...pendingApprovalEvents, ...rejectedEvents]}
              />
            )}
            {futureEvents.length > 0 && (
              <HomeSectionMobile
                title="Próximos eventos"
                events={futureEvents.sort((a, b) =>
                  compareAsc(parseISO(a.endDate), parseISO(b.endDate)),
                )}
              />
            )}
            {pastEvents.length > 0 && (
              <HomeSectionMobile
                title="Eventos passados"
                events={pastEvents.sort((a, b) =>
                  compareDesc(parseISO(a.endDate), parseISO(b.endDate)),
                )}
              />
            )}
            {allEvents.length === 0 && (
              <div className="mt-20 flex w-full flex-col items-center gap-6">
                <span className="text-center font-light">
                  Você ainda não possui eventos na Gandaya.{' '}
                  {canCreateEvent &&
                    'Clique no botão abaixo e faça o seu primeiro evento.'}
                </span>
                {canCreateEvent && (
                  <div className="h-12 w-[300px]">
                    <Button
                      enabled
                      text="Criar primeiro evento"
                      onClick={() => {
                        navigate('/create-event', {
                          state: { resetStore: 1 },
                        })

                        updateAnyState({ typeOperation: 'Create' })
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {showTeamsModal && (
            <OrganizationsModal
              allOrganizations={allOrganizations}
              closeModal={() => {
                setIsParentClosing(true)
                setTimeout(setShowTeamsModal, 200, false)
              }}
              currentOrganizationId={
                currentOrganization?.organizer.organizationId
              }
              isParentCLosing={isParentClosing}
            />
          )}
          {showInviteProducerModal && (
            <InviteProducerModal
              isParentCLosing={isParentClosing}
              closeModal={() => {
                setIsParentClosing(true)
                setTimeout(setShowInviteProducerModal, 200, false)
              }}
            />
          )}
        </div>
      </>
    )
  return (
    <div className=" flex min-h-full w-full flex-col items-center bg-[#2A2A2A] p-8">
      <div className="flex w-full max-w-[800px] flex-col gap-8 pb-8">
        {(canViewBalance || canViewTeam) && (
          <div className="flex w-full gap-4">
            {canViewBalance && (
              <HomeBalance
                balance={calculateOrganizationBalance()}
                textSize="text-4xl"
              />
            )}
            {canViewTeam && (
              <TeamCard
                teamImageKeys={
                  currentOrganization?.organizer.teamImageKey ?? []
                }
              />
            )}
          </div>
        )}
        {[...pendingApprovalEvents, ...rejectedEvents].length > 0 && (
          <HomeSectionDesktop
            title="Eventos desativados"
            events={[...pendingApprovalEvents, ...rejectedEvents]}
            isPastEvent={false}
          />
        )}
        {futureEvents.length > 0 && (
          <HomeSectionDesktop
            title="Próximos eventos"
            events={futureEvents.sort((a, b) =>
              compareAsc(parseISO(a.endDate), parseISO(b.endDate)),
            )}
            isPastEvent={false}
          />
        )}
        {pastEvents.length > 0 && (
          <HomeSectionDesktop
            title="Eventos passados"
            events={pastEvents.sort((a, b) =>
              compareDesc(parseISO(a.endDate), parseISO(b.endDate)),
            )}
            isPastEvent
          />
        )}
        {allEvents.length === 0 && (
          <div className="mt-8 flex w-full flex-col items-center gap-12">
            <span className="text-center text-lg font-medium">
              Você ainda não possui eventos na Gandaya.{' '}
              {canCreateEvent &&
                'Clique no botão abaixo e faça o seu primeiro evento.'}
            </span>
            {canCreateEvent && (
              <div className="h-12 w-[300px]">
                <Button
                  enabled
                  text="Criar primeiro evento"
                  onClick={() => {
                    navigate('/create-event', {
                      state: { resetStore: 1 },
                    })

                    updateAnyState({ typeOperation: 'Create' })
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
