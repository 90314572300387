import { forwardRef, useEffect, type ReactElement } from 'react'

interface DebounceInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string
  onValueChange: (value: string) => void
  onDebounce: (value: string) => void
  debounceTimeout?: number
}

const DebounceInput = forwardRef<HTMLInputElement, DebounceInputProps>(
  (
    { value, onValueChange, onDebounce, debounceTimeout, onChange, ...props },
    ref,
  ): ReactElement => {
    useEffect(() => {
      const handler = setTimeout(() => {
        onDebounce(value.trim())
      }, debounceTimeout ?? 400)

      return () => {
        clearTimeout(handler)
      }
    }, [value])

    return (
      <input
        ref={ref}
        type="text"
        value={value}
        onChange={(event) => {
          onValueChange(event.target.value)
        }}
        {...props}
      />
    )
  },
)

DebounceInput.displayName = 'DebounceInput'

export { DebounceInput }
