import { type ReactElement } from 'react'

interface CustomersAgeRangeIndicatorProps {
  minimumAge: number
  maximumAge: number
  ageRange: number[]
}

export function CustomersAgeRangeIndicator({
  minimumAge,
  maximumAge,
  ageRange,
}: CustomersAgeRangeIndicatorProps): ReactElement {
  if (ageRange.length === 1) {
    return <p className="text-white">{ageRange[0]} anos</p>
  }

  if (ageRange[0] === minimumAge - 1 && ageRange[1] === maximumAge + 1) {
    return <p className="text-white">Todas as idades</p>
  }

  if (ageRange[0] === minimumAge - 1) {
    return (
      <p className="text-dark-dark-gray">
        Até <span className="text-white">{ageRange[1]} anos</span>
      </p>
    )
  }

  if (ageRange[1] === 100) {
    return (
      <p className="text-dark-dark-gray">
        A partir de <span className="text-white">{ageRange[0]} anos</span>
      </p>
    )
  }

  return (
    <p className="text-white">
      {ageRange[0]} - {ageRange[1]} anos
    </p>
  )
}
