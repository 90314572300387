import { type ReactElement, useState } from 'react'
import * as Slider from '@radix-ui/react-slider'
import { twMerge } from 'tailwind-merge'

import { CustomersAgeRangeIndicator } from '@/components/Customers/CustomersAgeRangeIndicator'

import { FaCircle } from 'react-icons/fa6'

interface CustomersAgeFilterProps {
  ageRange: number[]
  showMultipleThumbs: boolean
  setAgeRange: React.Dispatch<React.SetStateAction<number[]>>
  setShowMultipleThumbs: React.Dispatch<React.SetStateAction<boolean>>
}

export function CustomersAgeFilter({
  ageRange,
  showMultipleThumbs,
  setAgeRange,
  setShowMultipleThumbs,
}: CustomersAgeFilterProps): ReactElement {
  const MINIMUM_AGE = 12
  const MAXIMUM_AGE = 99

  const [previousAgeRange, setPreviousAgeRange] = useState([18, 70])
  const [ageRangeInput, setAgeRangeInput] = useState([18, 70])

  return (
    <div className="flex w-full min-w-64 flex-col justify-between rounded-lg bg-dark-black p-3 xl:min-w-0">
      <div>
        <div className="flex items-center justify-between">
          <p className="self-start text-sm font-semibold xl:pt-1">Idade</p>
          <div className="flex gap-1">
            <button
              onClick={() => {
                if (showMultipleThumbs) {
                  setShowMultipleThumbs(false)
                  setPreviousAgeRange(ageRange)
                  setAgeRange((prev) => [prev[0] === 11 ? 12 : prev[0]])
                  setAgeRangeInput((prev) => [prev[0] === 11 ? 12 : prev[0]])
                }
              }}
              className={twMerge(
                'box-border flex h-8 w-8 items-center justify-center whitespace-nowrap rounded border-2 bg-[#202020] text-sm transition-colors',
                showMultipleThumbs
                  ? 'border-transparent'
                  : 'border-primary-main',
              )}
            >
              <FaCircle className="size-[5px]" />
            </button>
            <button
              onClick={() => {
                if (!showMultipleThumbs) {
                  setShowMultipleThumbs(true)
                  setAgeRange(previousAgeRange)
                  setAgeRangeInput(previousAgeRange)
                }
              }}
              className={twMerge(
                'box-border flex h-8 w-10 items-center justify-center gap-0.5 whitespace-nowrap rounded border-2 bg-[#202020] text-sm transition-colors',
                showMultipleThumbs
                  ? 'border-primary-main'
                  : 'border-transparent',
              )}
            >
              <FaCircle className="size-[5px]" />
              <FaCircle className="size-[5px]" />
            </button>
          </div>
        </div>
        <div className="mb-4 mt-6 xl:mt-5">
          <Slider.Root
            value={ageRangeInput}
            onValueChange={(value: number[]) => {
              setAgeRangeInput(value)
            }}
            onPointerUp={() => {
              setAgeRange(ageRangeInput)
            }}
            className="relative flex h-5 touch-none select-none items-center"
            defaultValue={[18, 70]}
            min={showMultipleThumbs ? MINIMUM_AGE - 1 : 0}
            max={100}
            step={1}
          >
            <Slider.Track className="relative h-[3px] grow rounded-full bg-white">
              <Slider.Range className="absolute h-full rounded-full bg-primary-main" />
            </Slider.Track>
            <Slider.Thumb
              className="block size-5 rounded-full bg-white focus:outline-none"
              aria-label="Volume"
            />
            {showMultipleThumbs && (
              <Slider.Thumb
                className="block size-5 rounded-full bg-white focus:outline-none"
                aria-label="Volume"
              />
            )}
          </Slider.Root>
        </div>
      </div>
      <div></div>
      <div className="w-full rounded-md border border-secondary-gray p-1.5 text-center text-sm transition-colors">
        <CustomersAgeRangeIndicator
          maximumAge={MAXIMUM_AGE}
          minimumAge={MINIMUM_AGE}
          ageRange={ageRangeInput}
        />
      </div>
    </div>
  )
}
