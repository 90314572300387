import { useState, type ReactElement, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { IoSearch } from 'react-icons/io5'
import { twMerge } from 'tailwind-merge'

interface LinkDesktopSearchBarProps {
  searchParam: string
  setSearchParam: (s: string) => void
  handleSearch: () => void
}

export function LinkDesktopSearchBar({
  searchParam,
  setSearchParam,
  handleSearch,
}: LinkDesktopSearchBarProps): ReactElement {
  const [onFocus, setOnFocus] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <motion.div
      className={twMerge(
        'flex items-center gap-2 rounded-md border border-transparent bg-dark-black px-2 py-0.5  transition-colors duration-100 ease-in-out hover:border-primary-main',
        !onFocus && 'hover:cursor-pointer',
      )}
      onClick={() => {
        if (!onFocus) {
          setOnFocus(true)

          if (inputRef.current !== null) {
            inputRef.current.focus()
          }
        }
      }}
      initial={{ width: 110 }}
      animate={{ width: onFocus ? 220 : 110 }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
      key="search-bar"
    >
      <input
        type="text"
        className={twMerge(
          'w-full bg-dark-black caret-white outline-none transition-colors duration-100 ease-in-out placeholder:text-base placeholder:font-bold placeholder:text-white',
          onFocus && 'placeholder:text-dark-dark-gray',
          !onFocus && 'hover:cursor-pointer',
        )}
        placeholder="Buscar"
        value={searchParam}
        onChange={(e) => {
          setSearchParam(e.target.value)
        }}
        onBlur={() => {
          if (searchParam === '') setOnFocus(false)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch()
          }
        }}
        ref={inputRef}
      />
      <div className="flex h-full items-center">
        {searchParam === '' && (
          <button
            className={twMerge(
              'flex size-6 items-center justify-center rounded-md transition-colors duration-300 ease-in-out',
              onFocus &&
                (searchParam !== '' ? 'bg-primary-main' : 'bg-[#656565]'),
            )}
            onClick={(e) => {
              e.preventDefault()
              handleSearch()
            }}
          >
            <IoSearch
              size={16}
              color={
                !onFocus ? '#fff' : searchParam === '' ? '#fff' : '#232323'
              }
              className="transition-colors duration-100 ease-in-out"
            />
          </button>
        )}
        <AnimatePresence>
          {onFocus && (
            <motion.button
              className="flex size-4 items-center justify-center rounded-full bg-white text-black"
              initial={{ width: 0, opacity: 0, marginLeft: 0 }}
              animate={{ width: 16, opacity: 1, marginLeft: 5 }}
              exit={{ width: 0, opacity: 0, marginLeft: 0 }}
              transition={{ duration: 0.1, ease: 'easeInOut' }}
              key="close"
              onClick={(e) => {
                e.preventDefault()
                setSearchParam('')
                setOnFocus(false)
              }}
            >
              <span className="rotate-45 text-base font-medium">+</span>
            </motion.button>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  )
}
