import { type ReactElement } from 'react'
import { Link, type LinkProps } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { SlArrowLeft } from 'react-icons/sl'

interface BackButtonProps
  extends LinkProps,
    React.RefAttributes<HTMLAnchorElement> {}

export function BackButton({
  to,
  className,
  ...props
}: BackButtonProps): ReactElement {
  return (
    <Link
      to={to}
      className={twMerge(
        'flex size-fit items-center gap-2 rounded-md bg-dark-black px-2 py-1 transition-colors hover:bg-dark-black/90',
        className,
      )}
      {...props}
    >
      <SlArrowLeft size={14} />
      <span className="text-sm font-bold">Voltar</span>
    </Link>
  )
}
