import { useState, type ReactElement } from 'react'
import {
  Bar,
  BarChart as BarChartRecharts,
  Cell,
  LabelList,
  ResponsiveContainer,
} from 'recharts'
import { type Props } from 'recharts/types/component/Label'

import { CustomLabelChart } from '../CustomLabelChart'

interface EventBarChartProps {
  data: Array<{
    pv: number
    label?: string
    percentage?: string
  }>
  onClickDo?: (index: number) => void
  onClickUndo?: () => void
  barSize?: number
  fullWidth?: boolean
  valueOnTop?: boolean
  dataKey?: string
  topLabel?: string
}

const customLabel = (props: Props): ReactElement => {
  const { x, y, width, height, value } = props
  return (
    <CustomLabelChart x={x} y={y} width={width} height={height} value={value} />
  )
}

export function BarChart({
  data,
  onClickDo,
  onClickUndo,
  barSize = 20,
  fullWidth = false,
  valueOnTop = false,
  dataKey = 'label',
  topLabel,
}: EventBarChartProps): ReactElement {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(true)

  const handleClick = (
    _: Props,
    index: number,
    event: React.MouseEvent<SVGPathElement, MouseEvent>,
  ): void => {
    event.stopPropagation()
    if (index === activeIndex) {
      setActiveIndex(null)
      if (onClickUndo !== undefined) onClickUndo()
    } else {
      barClicked = true
      setActiveIndex(index)
      if (onClickDo !== undefined) onClickDo(index)
    }
    if (activeAnimation) setActiveAnimation(false)
  }

  let barClicked = false

  const barTopMargin = valueOnTop ? 10 : topLabel !== undefined ? 20 : 0

  return (
    <ResponsiveContainer
      width={fullWidth ? '100%' : 20 + barSize * data.length}
      height="100%"
    >
      <BarChartRecharts
        data={data}
        onClick={() => {
          if (activeIndex !== null) {
            setActiveIndex(null)

            if (onClickUndo !== undefined && !barClicked) {
              onClickUndo()
            }
          }
        }}
        margin={{ top: barTopMargin, right: 0, bottom: 0, left: 0 }}
      >
        <Bar
          dataKey="pv"
          onClick={handleClick}
          radius={[2, 2, 0, 0]}
          isAnimationActive={activeAnimation}
        >
          {topLabel !== undefined && (
            <LabelList
              dataKey={topLabel}
              fontSize={14}
              fontWeight={700}
              dy={1}
              fill="#ffffff"
              position="top"
            />
          )}
          {valueOnTop ? (
            <LabelList
              dataKey={dataKey}
              fontSize={10}
              dy={3}
              fill="#ffffff"
              position="top"
            />
          ) : (
            <LabelList dataKey={dataKey} content={customLabel} />
          )}
          {data.map((_, index) => (
            <Cell
              cursor="pointer"
              fill={
                index === activeIndex || activeIndex === null
                  ? '#ffffff'
                  : '#656565'
              }
              key={`cell-${index}`}
            />
          ))}
        </Bar>
      </BarChartRecharts>
    </ResponsiveContainer>
  )
}
