import { type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { tv } from 'tailwind-variants'

import { BsArrowUpShort } from 'react-icons/bs'
import { twMerge } from 'tailwind-merge'

const ScrollToTopButtonVariants = tv({
  slots: {
    button:
      'fixed bottom-8 right-8 z-20 flex items-center justify-center rounded-full bg-white',
    icon: '',
  },
  variants: {
    size: {
      sm: {
        button: 'size-8',
        icon: 'size-6',
      },
      lg: {
        button: 'size-12',
        icon: 'size-7',
      },
    },
  },
})

interface ScrollToTopButtonProps {
  divRef: HTMLDivElement
  scrolled: boolean
  className?: string
  size?: 'sm' | 'lg'
}

export function ScrollToTopButton({
  divRef,
  scrolled,
  className,
  size = 'sm',
}: ScrollToTopButtonProps): ReactElement {
  const variants = ScrollToTopButtonVariants({ size })

  function handleScrollToTop(): void {
    if (divRef !== null) {
      divRef.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <AnimatePresence>
      {scrolled ? (
        <motion.button
          key="button"
          onClick={handleScrollToTop}
          className={twMerge(
            'fixed bottom-4 right-4 z-20 flex items-center justify-center rounded-full bg-white',
            variants.button(),
            className,
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <BsArrowUpShort className={variants.icon()} color="#181818" />
        </motion.button>
      ) : (
        <></>
      )}
    </AnimatePresence>
  )
}
