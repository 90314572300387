import { type ReactElement } from 'react'
import { motion } from 'framer-motion'

import { EventCard } from '@/components/Event/EventCard'

import { type Event } from '@/types/Organization'

import { hasClearance } from '@/utils/rbac'
import { datesToFormat } from '@/utils/formatData'

interface HomeSectionProps {
  title: string
  events: Event[]
}

export function HomeSectionMobile({
  title,
  events,
}: HomeSectionProps): ReactElement {
  return (
    <div className="">
      <h1 className="text-2xl font-bold">{title}</h1>
      {events.length > 0 ? (
        <motion.div className="mt-2">
          <motion.ul className="flex w-full flex-col gap-1.5">
            {events.map((event, index) => {
              const canViewAnalytics = hasClearance(
                event.roles.analytics,
                'VIEWER',
              )

              return (
                <motion.li key={index}>
                  <EventCard
                    key={event.eventId}
                    date={datesToFormat(event.date, event.endDate)}
                    imageUrl={event.backgroundArtImageKey}
                    location={
                      event.status === 'PENDING'
                        ? 'Em análise'
                        : event.status === 'REJECTED'
                          ? 'Rejeitado'
                          : canViewAnalytics
                            ? event.numberOfSoldTickets.total === 1
                              ? event.numberOfSoldTickets.total.toLocaleString() +
                                ' ingresso'
                              : event.numberOfSoldTickets.total.toLocaleString() +
                                ' ingressos'
                            : undefined
                    }
                    variation="small"
                    title={event.name}
                    alias={event.alias}
                    isClickable={event.status === 'APPROVED'}
                  />
                </motion.li>
              )
            })}
          </motion.ul>
        </motion.div>
      ) : (
        <div>
          <h1 className="text-sm font-light text-white">
            Sem eventos no momento
          </h1>
        </div>
      )}
    </div>
  )
}
