import { useState, type ElementType, type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { AnimatePresence, motion } from 'framer-motion'

import { Button } from '@/components/Global/Button'

import { useUserPermissions } from '@/store/user'
import { linkStore } from '@/store/link'

import { GoSearch } from 'react-icons/go'
import { IoOptions } from 'react-icons/io5'
import { TiSortAlphabetically } from 'react-icons/ti'
import { LuClock4, LuDollarSign } from 'react-icons/lu'

import { type IOptions } from '@/pages/Links'

import { hasClearance } from '@/utils/rbac'

interface LinkSearchBarProps {
  searchParam: string
  setSearchParam: (searchParam: string) => void
  handleSearch: () => void
  isSearching: boolean
  placeholder: string
  className?: string
  options: IOptions
  setOptions: (options: IOptions) => void
}

export function LinkSearchBar({
  searchParam,
  setSearchParam,
  handleSearch,
  isSearching,
  placeholder,
  className,
  options,
  setOptions,
}: LinkSearchBarProps): ReactElement {
  const [optionsMenuIsOpen, setOptionsMenuIsOpen] = useState(false)

  const { eventPermissions } = useUserPermissions()

  const canEditLinks = hasClearance(eventPermissions.link, 'EDITOR')

  const { setModalToOpen } = linkStore()

  return (
    <div
      className={twMerge(
        'relative transition-height duration-200',
        optionsMenuIsOpen ? 'h-[75px]' : 'h-[55px]',
      )}
    >
      <div
        className={twMerge(
          'absolute z-20 flex w-full items-center justify-between rounded-lg border-2 border-primary-main bg-background-main',
          className,
        )}
      >
        <div className=" flex w-4/5 flex-col p-2">
          <span className="text-[0.625rem] font-bold text-white">
            {placeholder}
          </span>
          <input
            type="text"
            className="w-full bg-background-main font-bold text-white focus:border-0 focus:outline-none"
            onChange={(e) => {
              setSearchParam(e.target.value)
            }}
            value={searchParam}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSearch()
            }}
          />
        </div>
        <div className="size-14">
          <Button
            icon={GoSearch as ElementType}
            enabled={!isSearching && searchParam !== ''}
            onClick={() => {
              handleSearch()
            }}
            className="rounded-lg border-4 border-background-main"
            isLoading={isSearching}
          />
        </div>
      </div>
      <AnimatePresence mode="wait">
        {optionsMenuIsOpen ? (
          <motion.div
            key="options"
            className="absolute left-0 top-[40px] z-10 flex h-20 items-end justify-center rounded-md bg-dark-black"
            initial={{ y: -40 }}
            animate={{ y: 0 }}
            exit={{ y: -40 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
          >
            <div className="flex items-center justify-center">
              <div className="flex gap-5 p-1.5">
                <div className="flex flex-col gap-0.5">
                  <span className="text-3xs font-bold">Filtro</span>
                  <button
                    className={twMerge(
                      'relative flex h-8 items-center justify-center rounded-md border border-background-main bg-background-main px-3.5 transition-colors duration-200',
                      options.filterZeros && 'border-primary-main',
                    )}
                    onClick={() => {
                      setOptions({
                        ...options,
                        filterZeros: !options.filterZeros,
                      })
                    }}
                  >
                    <span>0</span>
                    <div className="absolute left-[11px] top-[15px] h-px w-[15px] -rotate-45 rounded-full bg-white"></div>
                  </button>
                </div>
                <div className="flex flex-col gap-0.5">
                  <span className="text-3xs font-bold">Ordenação</span>
                  <div className="flex gap-1.5">
                    <button
                      className={twMerge(
                        'flex h-8 items-center justify-center rounded-md border border-background-main bg-background-main px-3 transition-colors duration-200',
                        options.orderAlphabetically && 'border-primary-main',
                      )}
                      onClick={() => {
                        setOptions({
                          ...options,
                          orderAlphabetically: !options.orderAlphabetically,
                          orderByDate: false,
                          orderBySales: false,
                        })
                      }}
                    >
                      <TiSortAlphabetically size={20} />
                    </button>
                    <button
                      className={twMerge(
                        'flex h-8 items-center justify-center rounded-md border border-background-main bg-background-main px-3 transition-colors duration-200',
                        options.orderByDate && 'border-primary-main',
                      )}
                      onClick={() => {
                        setOptions({
                          ...options,
                          orderByDate: !options.orderByDate,
                          orderAlphabetically: false,
                          orderBySales: false,
                        })
                      }}
                    >
                      <LuClock4 />
                    </button>
                    <button
                      className={twMerge(
                        'flex h-8 items-center justify-center rounded-md border border-background-main bg-background-main px-3 transition-colors duration-200',
                        options.orderBySales && 'border-primary-main',
                      )}
                      onClick={() => {
                        setOptions({
                          ...options,
                          orderByDate: false,
                          orderAlphabetically: false,
                          orderBySales: !options.orderBySales,
                        })
                      }}
                    >
                      <LuDollarSign />
                    </button>
                  </div>
                </div>
                <div className="self-end pb-1.5">
                  <button
                    className="flex size-5 rotate-45 items-center justify-center rounded-full bg-background-main text-xl"
                    onClick={() => {
                      setOptionsMenuIsOpen(false)
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        ) : (
          <motion.button
            key="openOptions"
            className="absolute -bottom-10 left-0 z-10 flex h-12 w-10 items-end justify-center rounded-md bg-dark-black"
            initial={{ y: -40 }}
            animate={{ y: 0 }}
            exit={{ y: -40 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            onClick={() => {
              setOptionsMenuIsOpen(true)
            }}
          >
            <div className="flex h-9 w-10 items-center justify-center">
              <IoOptions size={20} />
            </div>
          </motion.button>
        )}
      </AnimatePresence>
      {canEditLinks && (
        <motion.button
          key="addLink"
          className="absolute right-0 top-12 z-10 flex h-12 w-10 items-end justify-center rounded-md bg-dark-black"
          onClick={() => {
            setModalToOpen('NewLink')
          }}
          initial={{ y: -40 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          <div className="flex h-9 w-10 items-center justify-center">
            <span className="text-2xl">+</span>
          </div>
        </motion.button>
      )}
    </div>
  )
}
