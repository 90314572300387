import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

type Gender = 'male' | 'female' | 'unknown'

interface CustomersGenderFilterProps {
  selectedGenders: Gender[]
  setSelectedGenders: (gender: Gender[]) => void
}

export function CustomersGenderFilter({
  selectedGenders,
  setSelectedGenders,
}: CustomersGenderFilterProps): ReactElement {
  const options: Array<{
    value: Gender
    label: string
  }> = [
    { value: 'male', label: 'Masculino' },
    { value: 'female', label: 'Feminino' },
    { value: 'unknown', label: 'Indefinido' },
  ]

  return (
    <div className="max-w-56 shrink-0 rounded-lg bg-dark-black p-3 xl:max-w-none">
      <p className="mb-3 text-sm font-semibold">Gênero</p>
      <div className="flex flex-wrap gap-2">
        {options.map((option) => (
          <button
            key={option.value}
            onClick={() => {
              if (selectedGenders.includes(option.value)) {
                setSelectedGenders(
                  selectedGenders.filter(
                    (selectedGender) => selectedGender !== option.value,
                  ),
                )
              } else {
                setSelectedGenders([...selectedGenders, option.value])
              }
            }}
            className={twMerge(
              'box-border rounded-full border px-4 py-1 text-[0.8125rem] transition-colors',
              selectedGenders.includes(option.value)
                ? 'border-primary-main bg-primary-main text-dark-black hover:bg-primary-main/95'
                : 'border-white hover:bg-[#202020]',
            )}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  )
}
