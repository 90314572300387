import { type ReactElement } from 'react'

import { AreaChart } from '@/components/Chart/AreaChart'

import { useCurrentLink } from '@/store/link'

import { useGetLinkAnalytics } from '@/hooks/Link'
import { numberToReais } from '@/utils/formatNumber'

export function LinkAnalytics(): ReactElement {
  const { currentLink } = useCurrentLink()

  const { linkAnalytics } = useGetLinkAnalytics(currentLink?.id)

  const dataArea = linkAnalytics?.salesByDate.map((value) => {
    return {
      pv: value ?? 0,
    }
  })

  return (
    <div className="w-1/2">
      <h2 className="truncate text-4xl font-bold text-black">
        {currentLink?.code}
      </h2>
      <div className="h-40 w-full pb-4">
        <AreaChart
          data={dataArea !== undefined && dataArea?.length > 0 ? dataArea : []}
          variant="light"
        />
      </div>
      <div className="flex w-full flex-col gap-6">
        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-0.5">
            <span className="text-xs font-medium leading-3 text-[#A3A3A3]">
              Faturamento
            </span>
            <span className="text-3xl font-semibold leading-7 text-black">
              {numberToReais(linkAnalytics?.revenue ?? 0, 2)}
            </span>
          </div>
          <div className="flex flex-col items-end gap-0.5">
            <span className="text-xs font-medium leading-3 text-[#A3A3A3]">
              Vendas
            </span>
            <span className="text-3xl font-semibold leading-7 text-black">
              {linkAnalytics?.sales}
            </span>
          </div>
        </div>

        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-0.5">
            <span className="text-xs font-medium leading-3 text-[#A3A3A3]">
              Ticket médio
            </span>
            <span className="text-3xl font-semibold leading-7 text-black">
              {numberToReais(linkAnalytics?.averageTicket ?? 0, 2)}
            </span>
          </div>
          <div className="flex flex-col items-end gap-0.5">
            <span className="text-xs font-medium leading-3 text-[#A3A3A3]">
              Média de idade
            </span>
            <span className="text-3xl font-semibold leading-7 text-black">
              {linkAnalytics?.averageAge} anos
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
