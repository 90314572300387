import {
  type ReactNode,
  type ReactElement,
  useRef,
  useState,
  useEffect,
} from 'react'

import { ScrollToTopButton } from '@/components/Global/ScrollToTopButton'
import { Header } from '@/components/Global/Header'
import { SnackBar } from '@/components/Global/SnackBar'

import { useCurrentError } from '@/store/error'
import { wrapperStore } from '@/store/wrapper'
import { scrollStore } from '@/store/scroll'

interface MobileWrapperProps {
  children: ReactNode
}

export function MobileWrapper({ children }: MobileWrapperProps): ReactElement {
  const [scrolled, setScrolled] = useState(false)

  const mainDivRef = useRef<HTMLDivElement>(null)

  const { isError, errorMessage, showErrorWrapper } = useCurrentError()
  const { showHeader, showScrollToTop } = wrapperStore()
  const { setHandleScrollToTop, setHasScrolled } = scrollStore()

  function handleScrollToTop(): void {
    if (mainDivRef.current !== null) {
      mainDivRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    setHandleScrollToTop(handleScrollToTop)
    setHasScrolled(false)
  }, [])

  return (
    <div
      ref={mainDivRef}
      className="relative flex h-svh max-h-svh w-full max-w-full flex-col items-center overflow-y-scroll bg-[#2A2A2A]"
      onScroll={() => {
        if (mainDivRef.current?.scrollTop !== undefined) {
          setScrolled(mainDivRef.current?.scrollTop > 0)
          setHasScrolled(mainDivRef.current?.scrollTop > 0)
        }
      }}
    >
      {showHeader && (
        <div className="w-full">
          <Header variant="mobile" />
        </div>
      )}
      {children}
      {showScrollToTop && (
        <ScrollToTopButton
          divRef={mainDivRef.current!}
          scrolled={scrolled}
          className="bottom-20 right-4"
          size="lg"
        />
      )}
      {showErrorWrapper && (
        <div className="fixed bottom-0 z-20 h-0 w-full px-4">
          <SnackBar isMobile={true} isError={isError} message={errorMessage} />
        </div>
      )}
    </div>
  )
}
