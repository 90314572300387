import { type ReactElement, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { GoSearch, GoX } from 'react-icons/go'

interface CustomersSearchFilterInputProps {
  value: string
  setValue: (value: string) => void
}

export function CustomersSearchFilterInput({
  value,
  setValue,
}: CustomersSearchFilterInputProps): ReactElement {
  const [focused, setFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div
      className={twMerge(
        'box-border flex w-full cursor-text justify-between rounded-lg border-2 border-transparent bg-dark-black',
        focused && 'border-primary-main',
      )}
      onClick={() => inputRef.current?.focus()}
    >
      <input
        ref={inputRef}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
        className="w-full flex-1 rounded-lg bg-dark-black p-3 text-sm font-semibold outline-primary-dark placeholder:text-white focus:outline-none"
        placeholder="Buscar"
        type="text"
      />
      <div className="flex items-center justify-center overflow-hidden rounded-r-lg bg-dark-black">
        {value.length > 0 ? (
          <button
            onClick={() => {
              setValue('')
            }}
            className="flex h-full items-center justify-center px-3 transition-colors hover:bg-[#202020]"
          >
            <GoX strokeWidth={0.75} />
          </button>
        ) : (
          <div className="flex items-center justify-center px-3 transition-colors">
            <GoSearch strokeWidth={0.75} />
          </div>
        )}
      </div>
    </div>
  )
}
