import { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { BackButton } from '@/components/Global/BackButton'
import { CustomerEventTicketCard } from '@/components/CustomerEvent/CustomerEventTicketCard'
import { CustomerAnalyticsWrapper } from '@/components/CustomerAnalyticsWrapper/CustomerAnalyticsWrapper'
import { useGetCustomerEvent } from '@/hooks/Customers/'
import { useCurrentOrganization } from '@/store/organization'
import { parseISO } from 'date-fns'
import { isTruthy } from '@/utils/validation'
import { Loading } from '@/components/Global/Loading/Loading'
import { simplifiedFullDateFormat } from '@/utils/formatData'

export function CustomerEvent(): ReactElement {
  const { currentOrganization } = useCurrentOrganization()
  const organizationId: number = currentOrganization?.organizer
    .organizationId as number
  const { username, alias } = useParams()
  const { data, isLoading } = useGetCustomerEvent(
    organizationId,
    username!,
    alias!,
  )

  if (isLoading) return <Loading />

  return (
    <CustomerAnalyticsWrapper>
      {data !== undefined && (
        <div className="size-full p-4">
          <div className="mx-auto max-w-3xl">
            <header>
              <div className="mb-8">
                <BackButton to={`/customers/${username}/`} />
              </div>
              <div className="mb-4 flex items-center gap-4">
                <img
                  className="size-[4.5rem] rounded"
                  src={`${import.meta.env.VITE_S3 as string}/${data.event.imageKey}`}
                  alt={`Imagem de perfil do usuário @${data.user.username}.`}
                />
                <div>
                  <h1 className="pb-0.5 text-xl font-bold leading-tight tracking-tight">
                    {data.event.title}
                  </h1>
                  <p className="text-sm">
                    {data.event.startDate !== null &&
                      parseISO(data.event.startDate).toLocaleDateString(
                        'pt-BR',
                        {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        },
                      )}
                  </p>
                </div>
              </div>
              <div className="mb-8 flex items-center gap-2">
                <img
                  className="size-12 rounded-full"
                  src={`${import.meta.env.VITE_S3 as string}/${data.user.profileImageKey as string}`}
                  alt={`Imagem de perfil do usuário @${data.user.username}.`}
                />
                <div>
                  <h2 className="text-[0.9375rem] font-semibold leading-tight tracking-tight">
                    {data.user.firstName + ' ' + data.user.lastName}
                  </h2>
                  <p className="text-xs">@{data.user.username}</p>
                </div>
              </div>
            </header>
            <main className="pb-4">
              <h2 className="mb-4 text-xl font-semibold">
                Ingressos adquiridos para o evento
              </h2>
              <div className="grid grid-cols-1 gap-2.5 rounded-md bg-background-main p-4">
                {data.tickets.map((ticket) => (
                  <CustomerEventTicketCard
                    key={ticket.id}
                    title={
                      isTruthy(ticket.batchDescription)
                        ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                        : ticket.ticketSpecDescription
                    }
                    price={
                      ticket.price === undefined || ticket.price === 0
                        ? 'Grátis'
                        : `R$ ${ticket.price.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })}`
                    }
                    purchaseDate={parseISO(ticket.boughtAt).toLocaleDateString(
                      'pt-BR',
                      {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      },
                    )}
                    status={
                      ticket.validated
                        ? 'validated'
                        : ticket.cancelled
                          ? 'canceled'
                          : ticket.transfered
                            ? 'transferred'
                            : 'not-validated'
                    }
                    validatedAt={
                      ticket.validated
                        ? simplifiedFullDateFormat(ticket.validatedAt)
                        : ticket.cancelled
                          ? simplifiedFullDateFormat(ticket.cancelledAt)
                          : ticket.transfered
                            ? simplifiedFullDateFormat(ticket.transferedAt)
                            : undefined
                    }
                  />
                ))}
              </div>
            </main>
          </div>
        </div>
      )}
    </CustomerAnalyticsWrapper>
  )
}
