import useSWR from 'swr'

import { fetcher } from '@/services/axios'

interface GetResponseProps {
  user: {
    username: string
    profileImageKey: string | null
    firstName: string
    lastName: string
  }
  event: {
    imageKey: string
    title: string
    startDate: string | null
    endDate: string | null
  }
  tickets: Array<{
    id: number
    batchDescription?: string
    ticketSpecDescription: string
    boughtAt: string
    price?: number
    validated: boolean
    validatedAt: string
    cancelled: boolean
    cancelledAt: string
    transfered: boolean
    transferedAt: string
  }>
}

export const useGetCustomerEvent = (
  organizationId: number,
  username: string,
  eventAlias: string,
): {
  data: GetResponseProps | undefined
  isLoading: boolean
  error: string | undefined
} => {
  const { data, error, isLoading } = useSWR<GetResponseProps, string>(
    `/admin/analytics/organizations/${organizationId}/customers/${username}/events/${eventAlias}`,
    fetcher,
  )

  return {
    data,
    isLoading,
    error,
  }
}
