import useSWR from 'swr'

import { fetcher } from '@/services/axios'

interface GetResponseProps {
  numberOfCustomers: number
  averageAge: number
  predominantGender?: 'male' | 'female' | 'unknown'
}

interface FilterProps {
  sortBy?: string
  order?: string
  q?: string
  ageEq?: number
  ageLte?: number
  ageGte?: number
  gender?: string[]
  ticketSpecIds?: number[]
}

export const useGetCustomersSummary = (
  organizationId: number,
  filters: {
    sortBy?: string
    order?: string
    q?: string
    ageEq?: number
    ageLte?: number
    ageGte?: number
    gender?: string[]
    ticketSpecIds?: number[]
  },
): {
  data: GetResponseProps | undefined
  isLoading: boolean
  error: string | undefined
} => {
  const { data, error, isLoading } = useSWR<
    GetResponseProps,
    string,
    [string, FilterProps]
  >(
    [
      `/admin/analytics/organizations/${organizationId}/customers-summary`,
      filters,
    ],
    async ([url, filters]) =>
      await fetcher<GetResponseProps>(url, {
        params: filters,
      }),
  )

  return {
    data,
    isLoading,
    error,
  }
}
