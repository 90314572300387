import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { numberToReais } from '@/utils/formatNumber'

import { BarChart } from '../Chart/BarChart'
import { AreaChart } from '../Chart/AreaChart'

import { isTruthy } from '@/utils/validation'

import { deviceStore } from '@/store/device'

interface PeekAnalyticsProps {
  data?: {
    revenue: number
    revenueByTier: number[]
    genderSplit: Array<{
      gender: 'MALE' | 'FEMALE'
      count: number
    }>
    cashback: number
  }
}

export function PeekAnalytics({ data }: PeekAnalyticsProps): ReactElement {
  if (data === undefined)
    return (
      <div className="h-[188px] w-full animate-pulse rounded-lg bg-dark-black"></div>
    )

  const { isMobile } = deviceStore()

  const dataArea = data.revenueByTier.map((value) => {
    return {
      pv: value,
    }
  })

  const genderSplit = data.genderSplit.filter(
    (item) => item.gender === 'MALE' || item.gender === 'FEMALE',
  )

  const totalGenderSplit = genderSplit.reduce(
    (acc, curr) => acc + curr.count,
    0,
  )

  const genderBarData = genderSplit.map((item) => {
    return {
      pv: item.count,
      label: item.gender === 'MALE' ? 'Masculino' : 'Feminino',
      percentage: (item.count / totalGenderSplit).toLocaleString('pt-BR', {
        style: 'percent',
      }),
    }
  })

  const cashbackAmount = data.revenue * data.cashback
  const cashbackPercentage = data.cashback * 100

  return (
    <section className="flex w-full flex-col gap-2 rounded-lg bg-dark-black px-5 py-6">
      <div className="flex w-full items-end justify-between">
        <div className="flex w-[65%] flex-col gap-3">
          <div className="flex gap-4">
            <div className="flex flex-col">
              <span className="text-xs font-light">Faturamento total</span>
              <strong className="text-2xl font-bold">
                {numberToReais(data.revenue, 2)}
              </strong>
            </div>
            {isTruthy(data.cashback) && (
              <div className="flex flex-col">
                <span className="text-xs font-light">
                  Cashback <strong>{cashbackPercentage}%</strong>
                </span>
                <strong className="text-2xl font-bold text-primary-main">
                  {numberToReais(cashbackAmount, 2)}
                </strong>
              </div>
            )}
          </div>
          <div className="h-20 w-full">
            <AreaChart data={dataArea} />
          </div>
        </div>
        <div
          className={twMerge(
            'h-32 w-[30%]',
            isTruthy(data.cashback) && isMobile && 'h-20',
          )}
        >
          <BarChart data={genderBarData} fullWidth topLabel="percentage" />
        </div>
      </div>
    </section>
  )
}
