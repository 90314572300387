import {
  useState,
  type ReactElement,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { grantStore } from '@/store/grant'
import { useUserPermissions } from '@/store/user'
import { scrollStore } from '@/store/scroll'
import { deviceStore } from '@/store/device'
import { wrapperStore } from '@/store/wrapper'

import { Button } from '@/components/Global/Button'
import { GrantCreateModal } from '@/components/Grant/GrantCreateModal'
import { GrantInfoModal } from '@/components/Grant/GrantInfoModal'
import { GrantSelectTicketModal } from '@/components/Grant/GrantSelectTicketModal'
import { GrantUserCard } from '@/components/Grant/GrantUserCard'
import { Spinner } from '@/components/Global/Spinner'

import { type IGrant } from '@/types/Grant'

import { hasClearance } from '@/utils/rbac'

import { GoSearch } from 'react-icons/go'
import { BsArrowUpShort } from 'react-icons/bs'
import { useGetInfiniteGrants } from '@/hooks/Grant/'
import { useParams } from 'react-router-dom'
import { DebounceSearchBar } from '@/components/Global/DebounceSearchBar'

const TAKE = 50

export function Grant(): ReactElement {
  const [searchBarIsVisible, setSearchBarIsVisible] = useState(false)
  const [userGrantInfoModalIsOpen, setUserGrantInfoModalIsOpen] =
    useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [createGrantModalIsOpen, setCreateGrantModalIsOpen] = useState(false)
  const [selectTicketModalIsOpen, setSelectTicketModalIsOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState<number | null>(null)

  const { isMobile } = deviceStore()
  const { eventPermissions } = useUserPermissions()

  const { setHeaderButton } = wrapperStore()
  const { setCurrentUser } = grantStore()
  const { hasScrolled, handleScrollToTop } = scrollStore()

  const canEditGrant = hasClearance(eventPermissions.grant, 'EDITOR')

  const { alias: eventAlias } = useParams()
  const [searchQuery, setSearchQuery] = useState('')
  const { data, size, setSize, isValidating, isLoading } = useGetInfiniteGrants(
    eventAlias!,
    {
      term: searchQuery,
    },
    TAKE,
  )

  const observerRef = useRef<HTMLDivElement | null>(null)
  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const items = data !== undefined ? data.flatMap((page) => page) : []
  const hasMore = data === undefined || data[data.length - 1]?.length === TAKE
  const noItemsToShow =
    items.length === 0 &&
    !isValidating &&
    !isLoading &&
    searchQuery.length === 0

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries
      if (entry.isIntersecting && hasMore && !isLoading && !isValidating) {
        void setSize(size + 1)
      }
    },
    [size, hasMore, setSize, isLoading, isValidating],
  )

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 1.0,
    })
    if (observerRef.current !== null) observer.observe(observerRef.current)
    return () => {
      observer.disconnect()
    }
  }, [handleObserver])

  function handleOnClick(user: IGrant): void {
    setIsParentClosing(false)
    setUserGrantInfoModalIsOpen(true)
    setCurrentUser(user)
  }

  function handleModalClose(): void {
    setIsParentClosing(false)
  }

  function handleCreateGrantModalClose(): void {
    setCreateGrantModalIsOpen(false)
    setIsParentClosing(false)
    setSelectTicketModalIsOpen(true)
  }

  function calculateBottomOffset(
    isMobile: boolean,
    canEditGrant: boolean,
    searchBarIsVisible: boolean,
  ): number {
    if (isMobile && !canEditGrant) return searchBarIsVisible ? 82 : 28

    if (searchBarIsVisible) {
      return windowWidth !== null && windowWidth > 1200 ? 72 : 120
    }

    return 72
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const handleResize = (): void => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setHeaderButton(
      <div className="h-8 w-fit">
        {canEditGrant && (
          <Button
            enabled
            text="Enviar cortesia"
            className="px-4 text-sm"
            onClick={() => {
              setIsParentClosing(false)
              setCreateGrantModalIsOpen(true)
            }}
          />
        )}
      </div>,
    )
  }, [])

  useEffect(() => {
    if (searchBarIsVisible) {
      searchInputRef.current?.focus({
        preventScroll: true,
      })
    }
  }, [searchBarIsVisible])

  return (
    <>
      <div className="flex size-full max-w-[600px]">
        <div className="flex size-full flex-col gap-4 p-4">
          <main className="flex size-full flex-col gap-4">
            <div
              className={twMerge(
                'flex w-full flex-col transition-[padding]',
                items?.length === 0 && 'h-full items-center justify-center',
                searchBarIsVisible ? 'pb-16' : 'pb-4',
                canEditGrant
                  ? isMobile && searchBarIsVisible
                    ? 'last:pb-[116px]'
                    : 'last:pb-16'
                  : null,
              )}
            >
              {isLoading ? (
                <div>
                  <Spinner
                    borderWidth="border-4"
                    borderColor="border-primary-main/50"
                    bottomBorderColor="border-b-primary-main"
                  />
                </div>
              ) : items !== undefined ? (
                <div>
                  {items.length === 0 ? (
                    <div>
                      <p className="max-w-80 text-center text-xl font-semibold">
                        {searchQuery.trim() === ''
                          ? 'Você ainda não enviou nenhuma cortesia.'
                          : 'Nenhuma cortesia encontrada.'}
                      </p>
                    </div>
                  ) : (
                    <div>
                      {items.map((grant) => (
                        <GrantUserCard
                          grantData={grant}
                          handleOnClick={(grant: IGrant): void => {
                            handleOnClick(grant)
                          }}
                          key={grant.id}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ) : null}
              <div ref={observerRef} style={{ height: 1 }} />
              {!isLoading && isValidating && (
                <div className="mb-5 flex w-full items-center justify-center gap-4 pb-6">
                  <Spinner
                    borderWidth="border-4"
                    borderColor="border-primary-main/50"
                    bottomBorderColor="border-b-primary-main"
                  />
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
      <footer className="fixed bottom-0 z-10 flex w-full max-w-[582px] flex-col items-start rounded-t-lg bg-[#2a2a2a] pb-2 desktop:absolute desktop:flex-row desktop:pb-0">
        <AnimatePresence>
          {!noItemsToShow && searchBarIsVisible && (
            <motion.div
              key="search-bar-container"
              initial={{ y: 0, opacity: 0 }}
              animate={{ y: '-100%', opacity: 1 }}
              exit={{ y: 0, opacity: 0 }}
              transition={{ duration: 0.15, delay: 0.2 }}
              className="absolute w-full bg-[#2a2a2a] px-2 pb-2"
            >
              <div className="absolute -top-10 left-0 -z-10 h-20 w-full px-2">
                <div className="size-full bg-gradient-to-b from-transparent to-[#2a2a2a]" />
              </div>
              <DebounceSearchBar
                ref={searchInputRef}
                value={searchQuery}
                placeholder="Buscar cortesia..."
                onChangeText={setSearchQuery}
                onCancel={() => {
                  setSearchQuery('')
                  setSearchBarIsVisible(false)
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
        {canEditGrant && (
          <div className="w-full px-2">
            <Button
              className="block py-2.5 text-base desktop:hidden"
              enabled
              onClick={() => {
                setIsParentClosing(false)
                setCreateGrantModalIsOpen(true)
              }}
              text="Criar cortesia"
            />
          </div>
        )}
      </footer>
      {!noItemsToShow && (
        <motion.div
          key="page-btn-container"
          initial={{ height: 48, bottom: !canEditGrant && isMobile ? 28 : 80 }}
          animate={{
            height: hasScrolled ? 102 : 48,
            bottom: calculateBottomOffset(
              isMobile,
              canEditGrant,
              searchBarIsVisible,
            ),
          }}
          transition={{
            delay: hasScrolled ? 0 : 0.2,
            duration: 0.2,
            ease: 'easeInOut',
          }}
          className="fixed bottom-[134px] right-4 z-30 flex flex-col justify-start gap-1.5 pt-0 desktop:absolute desktop:bottom-[4.5rem] desktop:pt-12"
        >
          <button
            onClick={() => {
              setSearchBarIsVisible((prev) => !prev)
            }}
            className=" flex size-12 shrink-0 items-center justify-center rounded-full bg-primary-main transition-colors hover:bg-primary-main/90"
          >
            <GoSearch strokeWidth={0.625} className="size-5 text-dark-black" />
          </button>
          <AnimatePresence>
            {hasScrolled && (
              <motion.button
                onClick={handleScrollToTop}
                key="local-scroll-btn"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.2, delay: 0 } }}
                transition={{ delay: 0.2, duration: 0.2 }}
                className={twMerge(
                  'flex size-12 shrink-0 items-center justify-center rounded-full bg-white transition-colors hover:bg-[#f3f3f3]',
                )}
              >
                <BsArrowUpShort className="size-7" color="#181818" />
              </motion.button>
            )}
          </AnimatePresence>
        </motion.div>
      )}
      {userGrantInfoModalIsOpen && (
        <GrantInfoModal
          closeModal={(): void => {
            setIsParentClosing(true)
            setTimeout(setUserGrantInfoModalIsOpen, 400, false)
          }}
          isParentClosing={isParentClosing}
          handleModalClose={() => {
            setIsParentClosing(true)
            setTimeout(handleModalClose, 400)
          }}
        />
      )}
      {createGrantModalIsOpen && (
        <GrantCreateModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setCreateGrantModalIsOpen, 400, false)
          }}
          isParentClosing={isParentClosing}
          handleModalClose={() => {
            setIsParentClosing(true)
            setTimeout(handleCreateGrantModalClose, 400)
          }}
        />
      )}
      {selectTicketModalIsOpen && (
        <GrantSelectTicketModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setSelectTicketModalIsOpen, 400, false)
          }}
          isParentClosing={isParentClosing}
          handleModalClose={() => {
            setIsParentClosing(true)
            setTimeout(setSelectTicketModalIsOpen, 400, true)
          }}
        />
      )}
    </>
  )
}
