import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { TicketCounter } from '@/components/Global/TicketCounter'

interface CustomerEventTicketCardProps {
  title: string
  price: string
  purchaseDate: string
  status: 'validated' | 'not-validated' | 'canceled' | 'transferred'
  validatedAt?: string
}

export function CustomerEventTicketCard({
  title,
  price,
  purchaseDate,
  status,
  validatedAt,
}: CustomerEventTicketCardProps): ReactElement {
  return (
    <div className="flex items-center justify-between rounded-lg bg-dark-black p-3.5">
      <div className="flex items-center gap-3.5">
        <TicketCounter variant="dark" size="size-14" value={1} direction={1} />
        <div className="pb-1">
          <h3 className="pb-0.5 text-[0.9375rem] font-semibold leading-snug">
            {title}
          </h3>
          <div className="space-y-1">
            <p className="text-[0.8125rem]/none font-medium">{price}</p>
            <p className="text-[0.8125rem]/none">Compra: {purchaseDate}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-1.5 pr-1.5 pt-2">
        <div
          className={twMerge(
            'flex h-6 w-28 select-none items-center justify-center rounded-full text-sm font-semibold transition-colors',
            status === 'validated'
              ? 'bg-primary-main text-dark-black'
              : status === 'not-validated'
                ? 'bg-dark-dark-gray text-white'
                : status === 'transferred'
                  ? 'bg-[#ff9900] text-dark-black'
                  : 'bg-tonal-red text-dark-black',
          )}
        >
          {status === 'validated'
            ? 'Validado'
            : status === 'not-validated'
              ? 'Não validado'
              : status === 'transferred'
                ? 'Transferido'
                : 'Cancelado'}
        </div>
        {validatedAt !== undefined && (
          <p className="text-xs text-dark-dark-gray">{validatedAt}</p>
        )}
      </div>
    </div>
  )
}
