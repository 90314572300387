import { useState, type ReactElement, useEffect } from 'react'
import { motion } from 'framer-motion'
import { format } from 'date-fns'
import { twMerge } from 'tailwind-merge'

import { BarChart } from '@/components/Chart/BarChart'
import { DropDown } from '@/components/Global/DropDown'
import { SwitchButton } from '@/components/Global/SwitchButton'
import { Spinner } from '@/components/Global/Spinner'
import { AnimatedNumber } from '../AnimatedNumber'

import { useGetRevenuesByEventAlias } from '@/hooks/Analytics'

import {
  type PaymentByDateDTO,
  type AnalyticsRevenueDTO,
} from '@/types/Analytics'

import { isTruthy } from '@/utils/validation'
import { dateToDDMM } from '@/utils/formatData'
import { numberToReais } from '@/utils/formatNumber'
import { SlArrowDown } from 'react-icons/sl'

interface TicketSpecProps {
  value: string
  id: number | undefined
}

interface AnalyticsRevenueCardProps {
  alias: string
}

export function AnalyticsRevenueCard({
  alias,
}: AnalyticsRevenueCardProps): ReactElement {
  const [filterByTime, setFilterByTime] = useState(false)
  const [currentTime, setCurrentTime] = useState<string>('')
  const [ticketSpec, setTicketSpec] = useState<TicketSpecProps>({
    value: 'Todos os ingressos',
    id: undefined,
  })
  const [dropDownData, setDropDownData] = useState<TicketSpecProps[]>([])
  const [firstLoad, setFirstLoad] = useState(true)
  const [hasZeroRevenue, setHasZeroRevenue] = useState(false)
  const [revenuesData, setRevenuesData] = useState<AnalyticsRevenueDTO | null>(
    null,
  )
  const [partialRevenue, setPartialRevenue] = useState<PaymentByDateDTO | null>(
    null,
  )
  const [showDetails, setShowDetails] = useState(false)

  const { getRevenuesByEventAlias, isLoading } = useGetRevenuesByEventAlias(
    alias,
    filterByTime ? currentTime : undefined,
    ticketSpec.id,
  )

  useEffect(() => {
    setCurrentTime(format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXX"))
  }, [])

  useEffect(() => {
    if (revenuesData === null) return
    setDropDownData([
      {
        value: 'Todos os ingressos',
        id: undefined,
      },
      ...revenuesData.ticketSpecs.map((item) => {
        const value = isTruthy(item.batchDescription)
          ? `${item.batchDescription} - ${item.specDescription}`
          : item.specDescription
        return {
          value,
          id: item.specId,
        }
      }),
    ])
  }, [revenuesData])

  useEffect(() => {
    if (getRevenuesByEventAlias !== undefined)
      setRevenuesData(getRevenuesByEventAlias)
  }, [getRevenuesByEventAlias])

  useEffect(() => {
    if (revenuesData !== null && firstLoad) {
      setFirstLoad(false)

      if (revenuesData.revenue === 0) {
        setHasZeroRevenue(true)
      } else {
        setHasZeroRevenue(false)
      }
    }
  }, [revenuesData])

  if (firstLoad)
    return (
      <div className="h-[212px] w-full animate-pulse rounded-lg bg-dark-black" />
    )
  return (
    <div className="flex w-full flex-col justify-between rounded-lg bg-dark-black p-4 pb-3">
      <div className="flex w-full flex-col gap-4">
        <header
          className={twMerge(
            'flex justify-between',
            hasZeroRevenue && 'mb-0.5',
          )}
        >
          <div className="flex w-fit flex-col gap-2">
            <span className="text-xl font-black leading-4">
              Faturamento{' '}
              {partialRevenue !== null
                ? dateToDDMM(partialRevenue.date)
                : ' total'}
            </span>
            <AnimatedNumber
              val={
                partialRevenue !== null
                  ? partialRevenue.revenue ?? 0
                  : revenuesData?.revenue ?? 0
              }
              className="text-lg font-medium leading-4 text-primary-main"
            />
          </div>
          {isLoading && (
            <Spinner
              borderWidth="border-[3px]"
              borderColor="border-primary-main/50"
              bottomBorderColor="border-b-primary-main"
              width="w-4"
              height="h-4"
            />
          )}
        </header>
        {revenuesData !== null && !hasZeroRevenue && (
          <div className="relative w-full">
            <div className="h-20 w-full overflow-x-scroll px-2">
              {isTruthy(revenuesData) && (
                <BarChart
                  data={revenuesData.revenueByDate.map((item) => {
                    return {
                      pv: item.revenue,
                    }
                  })}
                  onClickDo={(n: number) => {
                    const currentPartialRevenue = revenuesData?.revenueByDate[n]
                    setPartialRevenue(currentPartialRevenue)
                  }}
                  onClickUndo={() => {
                    setPartialRevenue(null)
                  }}
                />
              )}
            </div>
            <div className="absolute -left-0.5 top-0 z-50 h-full w-3 bg-gradient-to-r from-dark-black to-transparent" />
            <div className="absolute -right-0.5 top-0 z-50 h-full w-3 bg-gradient-to-r from-transparent to-dark-black" />
          </div>
        )}
      </div>
      {revenuesData !== null && !hasZeroRevenue && (
        <div className="mt-3 flex w-full justify-between">
          <div className="flex rounded-md bg-background-main px-2">
            {isTruthy(revenuesData) && (
              <DropDown
                dropDownData={dropDownData}
                handleOnChange={(ticketSpecId: number) => {
                  const item = revenuesData.ticketSpecs.find(
                    (item) => item.specId === ticketSpecId,
                  )
                  if (item === undefined) {
                    setTicketSpec({
                      value: 'Todos os ingressos',
                      id: undefined,
                    })
                  } else {
                    const value = isTruthy(item.batchDescription)
                      ? `${item.batchDescription} - ${item.specDescription}`
                      : item.specDescription
                    setTicketSpec({
                      value,
                      id: item.specId,
                    })
                  }
                }}
                value={ticketSpec.value}
              />
            )}
          </div>
          <div className="flex items-center gap-4">
            <div className="flex w-min flex-col">
              <span className="whitespace-nowrap text-xs font-light">
                Vendas antes das
              </span>
              <div className="flex justify-between">
                <span className="text-xs font-bold">
                  {currentTime.slice(11, 16)}
                </span>
                <div className="flex w-7 items-center">
                  <SwitchButton
                    active={filterByTime}
                    handleOnClick={() => {
                      setFilterByTime(!filterByTime)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <motion.div
        className="flex w-full flex-col overflow-hidden"
        key="details"
        initial={{ height: 0 }}
        animate={showDetails ? { height: 'auto' } : { height: 0 }}
        transition={{ duration: 0.25, ease: 'easeInOut' }}
      >
        <div className="relative">
          <div className="max-h-48 overflow-scroll py-2">
            {revenuesData?.revenueByDate.map((item) => {
              return (
                <div key={item.date} className="flex gap-4">
                  <span className="w-10">{dateToDDMM(item.date)}</span>
                  <span className="font-bold">
                    {numberToReais(item.revenue, 2)}
                  </span>
                </div>
              )
            })}
          </div>
          <div className="absolute left-0 top-0 z-50 h-2 w-full bg-gradient-to-b  from-dark-black to-transparent" />
          <div className="absolute -bottom-0.5 left-0 z-50 h-2 w-full bg-gradient-to-t  from-dark-black to-transparent" />
        </div>
      </motion.div>
      {(revenuesData?.revenueByDate?.length ?? 0) > 0 && (
        <motion.button
          className="mt-2 flex w-full items-center justify-center"
          onClick={() => {
            setShowDetails(!showDetails)
          }}
          initial={{ rotate: 0 }}
          animate={showDetails ? { rotate: 180 } : { rotate: 0 }}
          transition={{ duration: 0.25, ease: 'easeInOut' }}
        >
          <SlArrowDown size={10} className="w-5 stroke-[100px]" />
        </motion.button>
      )}
    </div>
  )
}
