import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface SvgComponentProps extends React.SVGProps<SVGSVGElement> {
  active?: 'up' | 'down' | 'none'
}

export function ArrowUpDownIcon({
  active = 'none',
  ...props
}: SvgComponentProps): ReactElement {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth={2}
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className={twMerge(
          'transition-colors',
          active === 'down' ? 'text-primary-main' : 'text-dark-dark-gray',
        )}
        d="m21 16-4 4-4-4"
      />
      <path
        className={twMerge(
          'transition-colors',
          active === 'down' ? 'text-primary-main' : 'text-dark-dark-gray',
        )}
        d="M17 20V4"
      />
      <path
        className={twMerge(
          'transition-colors',
          active === 'up' ? 'text-primary-main' : 'text-dark-dark-gray',
        )}
        d="m3 8 4-4 4 4"
      />
      <path
        className={twMerge(
          'transition-colors',
          active === 'up' ? 'text-primary-main' : 'text-dark-dark-gray',
        )}
        d="M7 4v16"
      />
    </svg>
  )
}
