import useSWR from 'swr'

import { fetcher } from '@/services/axios'

interface GetResponseProps {
  firstName: string
  lastName: string
  username: string
  birthdate: string | null
  email: string
  phoneNumber: string
  gender: string
  profileImageKey?: string
  events: Array<{
    alias: string
    imageKey: string
    title: string
    startDate: string | null
    endDate: string | null
    location: string | null
  }>
}

export const useGetCustomer = (
  organizationId: number,
  username: string,
): {
  data: GetResponseProps | undefined
  isLoading: boolean
  error: string | undefined
} => {
  const { data, error, isLoading } = useSWR<GetResponseProps, string>(
    `/admin/analytics/organizations/${organizationId}/customers/${username}`,
    fetcher,
  )

  return {
    data,
    isLoading,
    error,
  }
}
