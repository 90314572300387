import { type ReactElement } from 'react'

import { BackButton } from '@/components/Global/BackButton'
import { CustomerProfileEventCard } from '@/components/CustomerProfile/CustomerProfileEventCard'
import { CustomerAnalyticsWrapper } from '@/components/CustomerAnalyticsWrapper/CustomerAnalyticsWrapper'
import { CustomerProfileDetailsCard } from '@/components/CustomerProfile/CustomerProfileDetailsCard'

import { useGetCustomer } from '@/hooks/Customers'
import { useCurrentOrganization } from '@/store/organization'
import { useParams } from 'react-router-dom'
import { calculateAge, dateToFormat } from '@/utils/formatData'
import { Loading } from '@/components/Global/Loading/Loading'
import { formatPhoneNumber } from '@/utils/formatString'

export function CustomerProfile(): ReactElement {
  const { currentOrganization } = useCurrentOrganization()
  const organizationId: number = currentOrganization?.organizer
    .organizationId as number
  const { username } = useParams()

  const { data, isLoading } = useGetCustomer(organizationId, username!)

  if (isLoading) return <Loading />

  const age =
    data?.birthdate !== null && data?.birthdate !== undefined
      ? calculateAge(data.birthdate)
      : null

  return (
    <CustomerAnalyticsWrapper>
      {data !== undefined && (
        <div className="size-full p-4">
          <div className="mx-auto max-w-3xl">
            <header className="mb-8">
              <BackButton to="/customers" />
              <div className="my-6 flex items-center gap-4">
                <img
                  src={`${import.meta.env.VITE_S3 as string}/${data.profileImageKey as string}`}
                  alt={`Foto de perfil de @${data.username}`}
                  className={'size-20 rounded-full'}
                />
                <div>
                  <h1 className="text-2xl">{`${data.firstName} ${data.lastName}`}</h1>
                  <p>@{data.username}</p>
                </div>
              </div>
              <div className="flex gap-2 overflow-x-scroll">
                {age !== null && (
                  <CustomerProfileDetailsCard
                    label="Idade"
                    data={age >= 100 ? 'Indefinida' : `${age} anos`}
                  />
                )}
                <CustomerProfileDetailsCard
                  label="Gênero"
                  data={
                    data.gender === 'male'
                      ? 'Masculino'
                      : data.gender === 'female'
                        ? 'Feminino'
                        : 'Indefinido'
                  }
                />
                <CustomerProfileDetailsCard
                  label="Telefone"
                  data={formatPhoneNumber(data.phoneNumber)}
                />
                <CustomerProfileDetailsCard label="Email" data={data.email} />
                {/* <a
                  href="#"
                  className="flex size-12 min-w-12 items-center justify-center rounded-md bg-dark-black text-sm transition-colors hover:bg-dark-black/90"
                >
                  <FaInstagram className="size-5" />
                </a>
                <a
                  href="#"
                  className="flex size-12 min-w-12 items-center justify-center rounded-md bg-dark-black text-sm transition-colors hover:bg-dark-black/90"
                >
                  <FaTiktok className="size-5" />
                </a> */}
              </div>
            </header>
            <main className="pb-3">
              <h2 className="mb-4 text-lg font-bold">
                Eventos que {data.firstName} já foi
              </h2>
              <div className="grid grid-cols-2 gap-3 rounded-md bg-background-main p-4">
                {data.events.map((event) => (
                  <CustomerProfileEventCard
                    key={event.alias}
                    eventImageKey={event.imageKey}
                    eventAlias={event.alias}
                    eventName={event.title}
                    eventDate={dateToFormat(event.startDate as string)}
                    eventLocation={event.location ?? 'Local a definir'}
                  />
                ))}
              </div>
            </main>
          </div>
        </div>
      )}
    </CustomerAnalyticsWrapper>
  )
}
