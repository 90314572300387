import { useState, type ReactElement, useEffect } from 'react'

import { SearchBar } from '@/components/Global/SearchBar'
import { Spinner } from '@/components/Global/Spinner'
import { LinkUser } from './LinkUser'

import { useGetLinkUsers } from '@/hooks/Link'

import { useCurrentLink } from '@/store/link'
import { type ILinkUser } from '@/types/Links'

export function LinkUsers(): ReactElement {
  const [searchParam, setSearchParam] = useState('')
  const [renderedUsers, setRenderedUsers] = useState<ILinkUser[]>([])
  const [first50Users, setFirst50Users] = useState<ILinkUser[]>([])
  const [fetchMoreUsers, setFetchMoreUsers] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)

  const { currentLink } = useCurrentLink()

  const { getUsers, isLoading } = useGetLinkUsers()

  async function handleFirstFetch(): Promise<void> {
    const response = await getUsers(currentLink!.id, 0)

    if (response.status === 200) {
      setRenderedUsers(response.users ?? [])
      setFirst50Users(response.users ?? [])
    }
  }

  async function handleSearchUsers(): Promise<void> {
    const response = await getUsers(currentLink!.id, 0, searchParam)

    if (response.status === 200) {
      setRenderedUsers(response.users ?? [])
    }
  }

  async function handleGetMoreUsers(): Promise<void> {
    const response = await getUsers(currentLink!.id, currentPage, searchParam)

    if (response.status === 200) {
      setRenderedUsers((prev) => [...prev, ...response.users!])
      if (response.users!.length < 10) {
        setFetchMoreUsers(false)
      }
    }
  }

  useEffect(() => {
    void handleFirstFetch()
  }, [])

  useEffect(() => {
    if (searchParam === '') {
      setRenderedUsers(first50Users)
    }
  }, [searchParam])

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setCurrentPage((current) => current + 1)
      }
    })

    const loadSentry = document.querySelector('#loadSentry')
    if (loadSentry !== null) intersectionObserver.observe(loadSentry)

    return () => {
      intersectionObserver.disconnect()
    }
  }, [isLoading])

  useEffect(() => {
    if (currentPage > 0) {
      void handleGetMoreUsers()
    }
  }, [currentPage])

  return (
    <div className="flex h-full w-1/2 flex-col gap-2">
      <SearchBar
        variant="dark"
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        isSearching={isLoading}
        placeholder="Pesquisar usuário"
        handleSearch={() => {
          void handleSearchUsers()
        }}
      />
      <div className="relative">
        <div className="flex h-[240px] max-h-[240px] w-full flex-col gap-2 overflow-scroll py-2">
          {renderedUsers.map((user, index) => {
            return <LinkUser user={user} key={index} />
          })}
          {fetchMoreUsers && (
            <div
              id="loadSentry"
              className="my-8 flex w-full items-center justify-center gap-4 "
            >
              <Spinner
                borderWidth="border-4"
                borderColor="border-background-main/50"
                bottomBorderColor="border-b-background-main"
              />
            </div>
          )}
        </div>
        <div className="absolute left-0 top-0 z-50 h-2 w-full bg-gradient-to-b  from-[#fff] to-transparent" />
        <div className="absolute bottom-0 left-0 z-50 h-2 w-full bg-gradient-to-b  from-transparent to-[#fff]" />
      </div>
    </div>
  )
}
