import { create } from 'zustand'

interface ScrollState {
  handleScrollToTop: () => void
  hasScrolled: boolean

  setHandleScrollToTop: (fn: () => void) => void
  setHasScrolled: (value: boolean) => void
}

export const scrollStore = create<ScrollState>((set) => ({
  handleScrollToTop: () => null,
  hasScrolled: false,

  setHandleScrollToTop: (fn: () => void) => {
    set({ handleScrollToTop: fn })
  },
  setHasScrolled: (value: boolean) => {
    set({ hasScrolled: value })
  },
}))
