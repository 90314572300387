import { useEffect, useState, type ReactElement } from 'react'

import { BatchCard } from './BatchCard'
import { BatchModal } from './BatchModal'
import { TicketSpecModal } from '../TicketSpecModal'
import { ConfirmDeletionModal } from '../ConfirmDeletionModal'
import { Button } from '@/components/Global/Button'

import { ticketStore } from '@/store/tickets'
import { wrapperStore } from '@/store/wrapper'
import { useUserPermissions } from '@/store/user'
import { deviceStore } from '@/store/device'

import { type IFollower } from '@/types/Tickets'

import { hasClearance } from '@/utils/rbac'

import { GoPlus } from 'react-icons/go'

export function TicketsFullView(): ReactElement {
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [showBatchModal, setShowBatchModal] = useState(false)
  const [showTicketSpecModal, setShowTicketSpecModal] = useState(false)
  const [
    showConfirmTicketSpecDeletionModal,
    setShowConfirmTicketSpecDeletionModal,
  ] = useState(false)

  const { ticketsSpecs, batches, setCurrentBatch, setCurrentTicketSpec } =
    ticketStore()
  const { setHeaderButton } = wrapperStore()
  const { eventPermissions } = useUserPermissions()
  const { isMobile } = deviceStore()

  const canCreateBatch = hasClearance(eventPermissions.tickets, 'EDITOR')

  const fullBatches = batches.map((batch) => {
    const tempTicketSpecs = ticketsSpecs.filter((ticketSpec) => {
      return ticketSpec.batchId === batch.id
    })

    return {
      ...batch,
      ticketSpecs: tempTicketSpecs,
    }
  })

  const ticketSpecDropDownData: IFollower[] = [
    {
      id: undefined,
      ticketSpecDescription: 'Nenhum',
      batchDescription: undefined,
    },
    ...fullBatches.flatMap((batch) =>
      batch.ticketSpecs.map((ticketSpec) => {
        return {
          batchDescription: batch.description,
          ticketSpecDescription: ticketSpec.description,
          id: ticketSpec.id,
        }
      }),
    ),
  ]

  const batchDropDownData: IFollower[] = [
    {
      id: undefined,
      batchDescription: 'Nenhum',
    },
    ...batches.map((batch) => {
      return {
        id: batch.id,
        batchDescription: batch.description,
      }
    }),
  ]

  useEffect(() => {
    setHeaderButton(
      <div className="h-8 w-fit">
        {canCreateBatch && (
          <Button
            enabled
            text="Adicionar lote"
            className="px-4 text-sm"
            onClick={() => {
              setCurrentBatch(undefined)
              setCurrentTicketSpec(undefined)
              setShowBatchModal(true)
            }}
          />
        )}
      </div>,
    )
  }, [])

  return (
    <div className="h-fit w-full">
      <div className="mb-4 flex flex-col items-center gap-8">
        <ul className="flex w-full flex-col gap-4">
          {fullBatches?.length === 0 ? (
            <div className="flex w-full justify-center">
              <span>Adicione um lote a este evento.</span>
            </div>
          ) : (
            fullBatches?.map((batch) => {
              return (
                <li key={batch.id}>
                  <BatchCard
                    key={batch.id}
                    batch={batch}
                    ticketSpecDropDownData={ticketSpecDropDownData}
                    batchDropDownData={batchDropDownData}
                    openTicketSpecModal={() => {
                      setShowTicketSpecModal(true)
                    }}
                    openBatchModal={() => {
                      setShowBatchModal(true)
                    }}
                    openConfirmDeletionModal={() => {
                      setShowConfirmTicketSpecDeletionModal(true)
                    }}
                  />
                </li>
              )
            })
          )}
        </ul>
        {isMobile && (
          <button
            className="fixed bottom-6 right-4 flex size-12 items-center justify-center rounded-full bg-primary-main"
            onClick={() => {
              setCurrentBatch(undefined)
              setShowBatchModal(true)
            }}
          >
            <GoPlus size={28} color="#232323" />
          </button>
        )}
      </div>
      {showBatchModal && (
        <BatchModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowBatchModal, 400, false)
          }}
          isParentClosing={isParentClosing}
        />
      )}
      {showTicketSpecModal && (
        <TicketSpecModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowTicketSpecModal, 400, false)
          }}
          isParentClosing={isParentClosing}
        />
      )}
      {showConfirmTicketSpecDeletionModal && (
        <ConfirmDeletionModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowConfirmTicketSpecDeletionModal, 400, false)
          }}
          isParentClosing={isParentClosing}
        />
      )}
    </div>
  )
}
