import useSWRInfinite from 'swr/infinite'
import { fetcher } from '@/services/axios'
import { type IGrant } from '@/types/Grant'

export const useGetInfiniteGrants = (
  eventAlias: string,
  queryParams: { term: string },
  take: number = 50,
): {
  data: IGrant[][] | undefined
  size: number
  setSize: (
    size: number | ((_size: number) => number),
  ) => Promise<IGrant[][] | undefined>
  isValidating: boolean
  isLoading: boolean
} => {
  const { data, size, setSize, isValidating, isLoading } = useSWRInfinite<
    IGrant[],
    string,
    (index: number) => [
      string,
      {
        skip?: number
        take?: number
        term: string
      },
    ]
  >(
    (index) => [
      `/admin/events/${eventAlias}/grants`,
      { skip: index * take, take, ...queryParams },
    ],
    async ([url, params]) => await fetcher<IGrant[]>(url, { params }),
    {
      revalidateFirstPage: false,
    },
  )

  return {
    data,
    size,
    setSize,
    isValidating,
    isLoading,
  }
}
