import useSWRInfinite from 'swr/infinite'
import { fetcher } from '@/services/axios'
import { type IUserData } from '@/types/List'
import { type KeyedMutator } from 'swr'

export const useGetInfiniteListUsers = (
  eventAlias: string,
  queryParams: { term: string },
  take: number = 50,
): {
  data: IUserData[][] | undefined
  size: number
  setSize: (
    size: number | ((_size: number) => number),
  ) => Promise<IUserData[][] | undefined>
  isValidating: boolean
  isLoading: boolean
  mutate: KeyedMutator<IUserData[][]>
} => {
  const { data, size, setSize, isValidating, isLoading, mutate } =
    useSWRInfinite<
      IUserData[],
      string,
      (index: number) => [
        string,
        {
          skip?: number
          take?: number
          term: string
        },
      ]
    >(
      (index) => [
        `/admin/events/${eventAlias}/users`,
        { skip: index * take, take, ...queryParams },
      ],
      async ([url, params]) => await fetcher<IUserData[]>(url, { params }),
      {
        revalidateFirstPage: false,
      },
    )

  return {
    data,
    size,
    setSize,
    isValidating,
    isLoading,
    mutate,
  }
}
