import { useState, type ReactElement, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import * as ExcelJS from 'exceljs'
import { twMerge } from 'tailwind-merge'
import { useNavigate } from 'react-router-dom'

import { CommunicationCampaignSummaryModal } from '@/components/CommunicationCampaigns/CommunicationCampaignSummaryModal'
import { Button } from '@/components/Global/Button'
import { UploadIcon } from '@/components/Icons/UploadIcon'
import { DeleteIcon } from '@/components/Icons/DeleteIcon'
import { Calendar } from '@/components/Global/Calendar'

import { handleValidateXlsxFile, isTruthy } from '@/utils/validation'
import { timeMask } from '@/utils/timeMask'
import { dateToBrazilTZ, dateToHour } from '@/utils/formatData'

import { errorStore } from '@/store/error'
import { useCommunicationCampaignStore } from '@/store/communicationCampaign'
import { useCurrentOrganization } from '@/store/organization'
import { useUserPermissions } from '@/store/user'

import { useCreateCommunicationCampaign } from '@/hooks/CreateCommunicationCampaign'

import {
  CAMPAIGN_NAME_ALREADY_EXISTS,
  INVALID_XLXS_FILE,
  XLSX_FILE_WRONG_FORMAT,
  CREATE_COMMUNICATION_CAMPAIGN,
} from '@/errors'

import { FaRegFileExcel } from 'react-icons/fa'
import { hasClearance } from '@/utils/rbac'

export function CreateCommunicationCampaign(): ReactElement {
  const {
    name,
    title,
    content,
    type,
    scheduledFor,
    estimatedSubscribers,
    estimatedPrice,
    xlsxFile,
    campaignNamesInUse,
    updateProperty,
    reset: resetCommunicationCampaignStore,
  } = useCommunicationCampaignStore()

  const [nameInUse, setNameInUse] = useState(false)
  const [date, setDate] = useState<string | undefined>(new Date().toISOString())
  const [time, setTime] = useState(dateToHour(scheduledFor))
  const [timeError, setTimeError] = useState(false)

  const [isParentClosing, setIsParentClosing] = useState(false)
  const [showSummary, setShowSummary] = useState(false)

  const { showError } = errorStore()

  const { currentOrganization } = useCurrentOrganization()

  const { organizationPermissions } = useUserPermissions()

  const { createCommunicationCampaign, isLoading } =
    useCreateCommunicationCampaign(
      currentOrganization?.organizer.organizationId ?? 0,
    )

  const navigate = useNavigate()

  function setDateHandler(): void {
    if (time === '') return
    const hour = time.split(':')[0]
    const minutes = time.split(':')[1]

    if (Number(minutes) > 59 || Number(hour) > 24) {
      setTimeError(true)
      return
    } else {
      setTimeError(false)
    }

    let tempDate
    if (date !== undefined) {
      tempDate = new Date(date)
    } else {
      tempDate = new Date()
    }
    tempDate.setHours(Number(hour))
    tempDate.setMinutes(Number(minutes))
    tempDate.setSeconds(0)
    tempDate.setMilliseconds(0)

    updateProperty('scheduledFor', dateToBrazilTZ(tempDate.toISOString()))
  }

  async function generateXLSX(): Promise<void> {
    // Sample data
    const data = [['Nome', 'Email', 'Celular']]

    // Create a workbook and add a worksheet
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet 1')

    // Add data to the worksheet
    data.forEach((row) => {
      worksheet.addRow(row)
    })

    // Save the workbook as an XLSX file
    const buffer = await workbook.xlsx.writeBuffer()

    // Create a Blob from the buffer
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Create a download link and trigger the click event
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'Modelo_Gandaya.xlsx'
    link.click()

    // Clean up
    URL.revokeObjectURL(link.href)
  }

  function analyzeFile(xlsxFile: File): void {
    const reader = new FileReader()

    reader.onload = async (e) => {
      if (e.target === null) return
      const buffer = e.target.result
      const workbook = new ExcelJS.Workbook()
      await workbook.xlsx.load(buffer as ArrayBuffer)

      // Assuming the first sheet is the one you want to analyze
      const worksheet = workbook.getWorksheet(1)

      // Count the number of rows
      const rowCount = worksheet!.rowCount

      // Read the first row to check headers
      const firstRow = worksheet!.getRow(1).values

      // Check if the first three columns have the specified headers
      const headerCheckResult =
        Array.isArray(firstRow) &&
        firstRow.length >= 4 &&
        typeof firstRow[1] === 'string' &&
        typeof firstRow[2] === 'string' &&
        typeof firstRow[3] === 'string' &&
        firstRow[1] === 'Nome' &&
        firstRow[2] === 'Email' &&
        firstRow[3] === 'Celular'

      if (!headerCheckResult) {
        showError(XLSX_FILE_WRONG_FORMAT)
        return
      }

      updateProperty('estimatedSubscribers', rowCount - 1)

      let price = 0
      if (type === 'PUSH') {
        price = 0.2 * (rowCount - 1)
      } else {
        price = 0.3 * (rowCount - 1)
      }
      updateProperty('estimatedPrice', price)
      updateProperty('xlsxFile', xlsxFile)
    }

    reader.readAsArrayBuffer(xlsxFile)
  }

  async function handleCreateCommunicationCampaign(): Promise<void> {
    const body = {
      name,
      type,
      title,
      content,
      scheduledFor,
      estimatedPrice,
      estimatedSubscribers,
      xlsxFile,
    }
    const response = await createCommunicationCampaign(body)

    if (response.status !== 200) {
      showError(CREATE_COMMUNICATION_CAMPAIGN)
    } else {
      resetCommunicationCampaignStore()
      navigate('/communication-campaigns')
    }
  }

  useEffect(() => {
    updateProperty('xlsxFile', null)
  }, [])

  useEffect(() => {
    if (
      !hasClearance(organizationPermissions.communicationCampaign, 'EDITOR')
    ) {
      navigate('/')
    }
  }, [organizationPermissions])

  return (
    <div className="relative mt-4 flex size-full max-w-[500px] flex-col self-center">
      <div className="flex flex-col gap-4 p-4">
        <div className="relative mb-2 flex items-center gap-2">
          <input
            type="text"
            placeholder="Nome da Campanha"
            value={name}
            onChange={(e) => {
              updateProperty('name', e.target.value)
            }}
            onBlur={(e) => {
              if (campaignNamesInUse.includes(e.target.value)) {
                setNameInUse(true)
              } else {
                setNameInUse(false)
              }
            }}
            className="w-full bg-transparent text-3xl font-black outline-none placeholder:text-[#D9D9D9]"
          />
          {nameInUse && (
            <span className="absolute -bottom-4 text-xs leading-3 text-tonal-red">
              {CAMPAIGN_NAME_ALREADY_EXISTS}
            </span>
          )}
        </div>
        <div className="relative flex w-full flex-col gap-2">
          <span className="text-2xl font-black">Título</span>
          <input
            type="text"
            value={title}
            onChange={(e) => {
              updateProperty('title', e.target.value)
            }}
            className="h-10 border-b-2 border-x-background-main border-b-primary-main border-t-background-main bg-transparent py-2 outline-none transition-all duration-200 ease-in-out focus:rounded-lg focus:border-2 focus:border-primary-main focus:px-2 disabled:border-b-dark-dark-gray"
          />
        </div>
        <div className="flex w-full flex-col gap-2">
          <span className="text-2xl font-black">Conteúdo</span>
          <textarea
            value={content}
            onChange={(e) => {
              updateProperty('content', e.target.value)
            }}
            className="h-24 resize-none rounded-lg border-2 border-primary-main bg-transparent p-2 outline-none transition-all duration-200 ease-in-out focus:rounded-lg focus:border-2 disabled:border-dark-dark-gray"
          />
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-2xl font-black">Contatos</span>
          <label htmlFor="file">
            <AnimatePresence mode="wait">
              {xlsxFile !== null ? (
                <motion.div
                  key="file"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, height: 128 }}
                  transition={{ duration: 0.1, ease: 'easeInOut' }}
                  className="relative flex h-20 items-center justify-between rounded-lg bg-dark-black px-4"
                >
                  <div className="flex max-w-full items-center gap-2">
                    <FaRegFileExcel
                      size={24}
                      color="#656565"
                      className="size-10 rounded-lg bg-dark-light-gray p-2"
                    />
                    <span className="line-clamp-1 w-full text-ellipsis text-sm">
                      {xlsxFile.name}
                    </span>
                  </div>
                  <DeleteIcon
                    onClick={(e) => {
                      e.preventDefault()
                      updateProperty('xlsxFile', null)
                    }}
                  />
                </motion.div>
              ) : (
                <motion.div
                  key="selectFile"
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, height: 80 }}
                  transition={{ duration: 0.1, ease: 'easeInOut' }}
                  className="flex h-32 w-full flex-col items-center justify-center gap-2 rounded-lg border-2 border-dark-dark-gray"
                >
                  <UploadIcon iconColor="#D5FF5C" className="size-6" />
                  <span className="text-center text-sm">
                    Faça upload de uma planilha com os contatos.
                  </span>

                  <span className="text-xs">
                    Baixe o nosso template de envio{' '}
                    <span
                      className="text-xs italic text-primary-main underline"
                      onClick={(e) => {
                        e.preventDefault()
                        void generateXLSX()
                      }}
                    >
                      aqui
                    </span>
                  </span>
                </motion.div>
              )}
            </AnimatePresence>

            <input
              id="file"
              className="hidden"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files === null || xlsxFile !== null) return
                const isXlsx = handleValidateXlsxFile(e.target.files?.[0])
                if (!isXlsx) {
                  showError(INVALID_XLXS_FILE)
                  return
                }
                analyzeFile(e.target.files?.[0])
              }}
            />
          </label>
        </div>
        <div className="flex flex-col">
          <span className="mb-4 text-2xl font-black">Data de envio</span>
          <Calendar
            selectedDate={date}
            onDateSelected={(date: Date) => {
              setDate(date.toISOString())
            }}
            highlightedFontColor="text-background-main"
          />
          <div className="flex w-full items-center justify-center gap-2">
            <span className="font-bold">Horário: </span>
            <input
              type="text"
              placeholder="21:30"
              name="time"
              autoCorrect="off"
              autoCapitalize="none"
              aria-autocomplete="none"
              className={twMerge(
                'h-[34px] w-[60px] rounded-none border-b-2 border-x-background-main border-b-primary-main border-t-background-main bg-transparent px-2 py-1 text-center outline-none transition-all duration-200 ease-in-out focus:rounded-lg focus:border-2 focus:border-x-primary-main focus:border-t-primary-main',
                timeError &&
                  'border-tonal-red focus:border-tonal-red focus:border-x-tonal-red',
              )}
              onChange={(e) => {
                setTime(timeMask(e.target.value))
                setDateHandler()
              }}
              value={time}
              onBlur={setDateHandler}
            />
          </div>
          <span
            className={twMerge(
              'mt-3 w-full text-center text-xs text-tonal-red',
              timeError ? 'opacity-100' : 'opacity-0',
            )}
          >
            Horário inválido.
          </span>
        </div>
        <div className="flex w-full flex-col items-center">
          <div className="mb-2 h-12 w-full max-w-[300px]">
            <Button
              text="Criar"
              enabled={
                isTruthy(name) &&
                !nameInUse &&
                isTruthy(title) &&
                isTruthy(content) &&
                isTruthy(xlsxFile) &&
                isTruthy(scheduledFor) &&
                isTruthy(time)
              }
              onClick={() => {
                setShowSummary(true)
              }}
            />
          </div>
        </div>

        {showSummary && (
          <CommunicationCampaignSummaryModal
            isParentClosing={isParentClosing}
            closeModal={() => {
              setIsParentClosing(true)
              setTimeout(setIsParentClosing, 400, false)
              setTimeout(setShowSummary, 400, false)
            }}
            variant="CREATE"
            campaignData={{
              name,
              type,
              communication: {
                title,
                content,
              },
              scheduledFor,
              estimatedPrice,
              estimatedSubscribers,
            }}
            confirmCampaign={() => {
              void handleCreateCommunicationCampaign()
            }}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  )
}
