import useSWRInfinite from 'swr/infinite'
import { fetcher } from '@/services/axios'

interface GetResponseProps {
  id: number
  firstName: string
  lastName: string
  username: string
  gender: string
  birthdate: string
  profileImageKey: string
  events: Array<{
    id: number
    startDate: string | null
    imageKey: string
  }>
}

interface FilterProps {
  q: string
  sortBy: 'name' | 'age' | 'engagement'
  order: string
  gender: Array<'male' | 'female' | 'unknown'>
  ageEq: number | undefined
  ageGte: number | undefined
  ageLte: number | undefined
  ticketSpecIds: number[]
}

const TAKE = 50

export const useGetInfiniteCustomers = (
  organizationId: number,
  queryParams: FilterProps,
  take: number = TAKE,
): {
  data: GetResponseProps[][] | undefined
  size: number
  setSize: (
    size: number | ((_size: number) => number),
  ) => Promise<GetResponseProps[][] | undefined>
  isValidating: boolean
  isLoading: boolean
} => {
  const { data, size, setSize, isValidating, isLoading } = useSWRInfinite<
    GetResponseProps[],
    string,
    (index: number) => [
      string,
      {
        skip?: number
        take?: number
        q: string
        sortBy: 'name' | 'age' | 'engagement'
        order: string
        gender: Array<'male' | 'female' | 'unknown'>
        ageEq: number | undefined
        ageGte: number | undefined
        ageLte: number | undefined
        ticketSpecIds: number[]
      },
    ]
  >(
    (index) => [
      `/admin/analytics/organizations/${organizationId}/customers`,
      { skip: index * take, take, ...queryParams },
    ],
    async ([url, params]) => await fetcher<GetResponseProps[]>(url, { params }),
    {
      revalidateFirstPage: false,
    },
  )

  return {
    data,
    size,
    setSize,
    isValidating,
    isLoading,
  }
}
